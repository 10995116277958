import { useEffect, useState } from "react";
import { EmptyView, ErrorView, Loader, Paginate, Table } from "components/ui";

import WithdrawalsModal from "./WithdrawalsModal/WithdrawalsModal";

import WithdrawalsTableActions from "./WithdrawalsTableActions/WithdrawalsTableActions";
import { getFormattedDate } from "utils/helper";
import { apiQueryMethods, apiUrls } from "utils/api";

import { dataQueryStatus } from "utils/dataQueryStatus";
import API from "utils/api/API";

const head = [
  "Received By",
  "Amount",
  "From",
  "Bank Info",
  "Status",
  "Description",
  "Date",
  "Action",
];

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const WithdrawalsTable = () => {
  const [selectedTrans, setSelectedTrans] = useState({});

  const [status, setStatus] = useState(IDLE);
  const [transactions, setTransactions] = useState([]);
  const [filter, updateFilter] = useState({
    limit: 5,
    page: 1,
  });

  const [action, setAction] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleAction = (actionType: string, selectedTrans: Object) => {
    setAction(actionType);
    setSelectedTrans(selectedTrans);
    setOpenModal(true);
  };

  const getTransactions = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getTransactions,
      params: {
        ...filter,
        type: "Withdrawal",
      },
    })
      .then((resp) => {
        const result = resp.data.data;

        const formattedList = result?.map((eachTrans: any) => {
          const { accountNumber, bankName } = eachTrans?.paymentInfo || {};
          return {
            receiverName: eachTrans?.receiverName
              ? eachTrans?.receiverName
              : "N/A",
            amount: <div>{`${eachTrans?.currency} ${eachTrans?.amount}`}</div>,
            from: eachTrans?.sender,
            bankInfo: (
              <>
                <p>Account : {accountNumber}</p>
                <p>Bank : {bankName}</p>
              </>
            ),
            status: eachTrans?.status,
            description: eachTrans?.description,
            date: <div>{getFormattedDate(eachTrans?.created_at)}</div>,
            action: <WithdrawalsTableActions handleAction={handleAction} />,
          };
        });

        setTransactions(formattedList);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setTransactions([]);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return (
          <>
            <Table
              head={head}
              body={transactions}
              tableClass="transactions--table"
            />
            <Paginate
              totalItems={100}
              pageSize={filter?.limit}
              currentPage={filter?.page}
              onResult={onResult}
            />
          </>
        );
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getTransactions} />;
      default:
        return "";
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      {renderBasedOnStatus()}
      <WithdrawalsModal
        openModal={openModal}
        actionType={action}
        setOpenModal={setOpenModal}
        selectedTrans={selectedTrans}
      />
    </>
  );
};

export default WithdrawalsTable;
