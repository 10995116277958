const adminUrl = "https://serviceandme-backend-admin.vercel.app/api/v1";

export const apiUrls = {
  getCategories: `${adminUrl}/categories`,
  createCategory: `${adminUrl}/categories`,
  logIn: `${adminUrl}/auth/login`,
  signUp: `${adminUrl}/auth/register/`,
  editCategory: (categoryId: string) => `${adminUrl}/categories/${categoryId}`,
  deleteCategory: (categoryId: string) =>
    `${adminUrl}/categories/${categoryId}`,
  getAdmins: `${adminUrl}/users/admins`,
  getCustomers: `${adminUrl}/users/customers`,
  getProviders: `${adminUrl}/users/providers`,
  getStats: `${adminUrl}/overview/`,
  getCustomersStat: `${adminUrl}/stats/customers`,
  getProvidersStat: `${adminUrl}/stats/providers`,
  getRequestsStat: `${adminUrl}/stats/requests`,
  getSearchesStat: `${adminUrl}/stats/searches`,
  getTransactionsStat: `${adminUrl}/stats/transactions`,
  getAppFeedback: `${adminUrl}/app-feedback`,
  getTransactions: `${adminUrl}/transactions`,
  getRequests: `${adminUrl}/requests/`,
  getUser: (email?: string, userType?: string, phoneNumber?: string) =>
    email
      ? `${adminUrl}/users?email=${email}&userType=${userType}`
      : `${adminUrl}/users?userType=${userType}&phoneNumber=${phoneNumber}`,
};

export const apiQueryMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};
