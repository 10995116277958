import Heading from "components/ui/Heading";

const CategoriesHeading = () => {
  return (
    <>
      <Heading title="Categories" subTitle="Manage Categories Here." />
    </>
  );
};

export default CategoriesHeading;
