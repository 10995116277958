import assets from "assets/indext";
import { Button } from "components/ui";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className="landing-page--hero">
        <h1 data-aos="zoom-in">
          {/* Request or <br /> <span>Offer to Earn.</span> */}
          Request or Offer <br /> <span>On-demand Home services</span>
        </h1>

        <p>
          We make it easy to Request or Offer on-demand Home services with a
          simple smartphone app.
        </p>
        <div className="hero__button">
          {/* <Link to="/download-app/">
            <Button text="Sign Up" />
          </Link> */}

          <Link to="/download-app/">
            <Button text="Download App" />
          </Link>
        </div>
        <div className="hero__image">
          <img
            className="bounce"
            src={assets.images?.mobileView}
            alt="Mobile View"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
