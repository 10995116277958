import { Col, Row } from "react-bootstrap";
import AdminsUserCard from "./AdminUserCard/AdminUserCard";

interface Props {
  handleAction: Function;
  admins: Array<{
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
  }>;
}

const AdminsBody = (props: Props) => {
  const { handleAction, admins } = props;

  return (
    <>
      <div>
        <Row>
          {admins.map((item, key) => (
            <Col key={key} xl={3}>
              <AdminsUserCard handleAction={handleAction} user={item} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default AdminsBody;
