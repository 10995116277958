import { Col, Row } from "react-bootstrap";
import { Card, FileInput, Icon } from "components/ui";

const PersonalPicture = () => {
  return (
    <>
      <div className="profile-pic">
        <Row>
          <Col>
            <Card className="profile-pic__image">
              <Icon icon="avatar" className="profile-pic__image-avatar" />
            </Card>
          </Col>
          <Col>
            <div className="profile-pic__select">
              <FileInput invertStyle />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PersonalPicture;
