import { Button } from "components/ui";
import "./OVSummaryButton.scss";

interface Props {
  handleOpenModal: Function;
}
const OVSummaryButton = (props: Props) => {
  const { handleOpenModal } = props;
  return (
    <>
      <div className="overview-button">
        <Button text="Invite Admin" onClick={handleOpenModal} />
      </div>
    </>
  );
};

export default OVSummaryButton;
