import { Search } from "components/ui";
import Heading from "components/ui/Heading";
import RequestsTable from "./RequestsTable/RequestsTable";
import "./Requests.scss";

const AllRequestsHeading = () => {
  return (
    <>
      <Heading title="Requests" subTitle="Manage all requests here" />
      <Search
        className="admin-requests-search"
        placeholder="Search for requests ..."
      />
    </>
  );
};

const AllRequests = () => {
  return (
    <>
      <div className="transactions">
        <AllRequestsHeading />
        <RequestsTable />
      </div>
    </>
  );
};

export default AllRequests;
