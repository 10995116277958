import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/ui/layout";
import AppRoutes from "./AppRoutes";
import { useEffect, useState } from "react";
import { getToken, getUser, storeToken, storeUser } from "storage";
import { AppContext } from "AppContext";

import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  const [user, setUser] = useState<{
    serviceName: string;
    description: string;
    email: string;
    firstName: string;
    lastName: string;
  }>(getUser());

  const [token, setToken] = useState(getToken());
  const updateUser = (data: any) => {
    storeUser(user);
    setUser(data);
  };

  const updateToken = (token: any) => {
    storeToken(token);
    setToken(token);
  };

  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 300,
      easing: "ease-in-sine",
      // anchorPlacement:""
    });
    Aos.refresh();
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          user,
          token,
          updateUser,
          updateToken,
        }}
      >
        <Router>
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </AppContext.Provider>
    </>
  );
}

export default App;
