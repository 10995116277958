import { Button, Input } from "components/ui";
import { useForm } from "react-hook-form";

import { useState } from "react";
import { dataQueryStatus } from "utils/dataQueryStatus";
import "./ChangeMyPasswordForm.scss";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;
const ChangeMyPasswordForm = () => {
  const [status, setStatus] = useState(IDLE);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("resp", data);
    setStatus(LOADING);
    setStatus(SUCCESS);
    setStatus(ERROR);
  };

  return (
    <>
      <form className="change-password" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Current Password"
          placeholder="Enter Your Current Password."
          inputClass="change-password__input"
          hasError={errors?.currentPassword ? true : false}
          name="currentPassword"
          register={register}
          required
        />
        <Input
          label="New Password"
          placeholder="Enter The New Password."
          inputClass="change-password__input"
          hasError={errors?.newPassword ? true : false}
          name="newPassword"
          register={register}
          required
        />
        <Input
          label="Confirm New Password"
          placeholder="Enter The New Password Again."
          inputClass="change-password__input"
          hasError={errors?.confirmPassword ? true : false}
          name="confirmPassword"
          register={register}
          required
        />
        <Button
          text="Update"
          className="change-password__button"
          status={status}
        />
      </form>
    </>
  );
};

export default ChangeMyPasswordForm;
