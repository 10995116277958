const icons = {
  linkedin:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662209760/WebApp/icons/linkedin-svgrepo-com_khfirp.svg",
  playstore:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1664323797/WebApp/icons/playstore-icon_dbch3z.png",
  appStore:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1675252881/WebApp/icons/apple-g827bd7524_1280-removebg-preview_xsjath.png",
};

export default icons;
