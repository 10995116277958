export const storeToken = (token: any) => {
  return localStorage.setItem("token", token);
};

export const storeUser = (user: any) => {
  return localStorage.setItem("user", JSON.stringify(user));
};

let pathName = window.location.pathname;
// let isAuthPath =
//   pathName.includes("login") ||
//   pathName.includes("signup") ||
//   pathName === "/" ||
//   pathName.includes("/download-app") ||
//   pathName.includes("/privacy-policy") ||
//   pathName.includes("terms-and-conditions");

let isAdminPath =
  pathName.includes("admin") &&
  !pathName.includes("login") &&
  !pathName.includes("signup");

export const getUser = () => {
  let user = localStorage.getItem("user");
  // return user ? JSON.parse(user || "{}") : "";
  return user
    ? JSON.parse(user || "{}")
    : isAdminPath && (window.location.href = "/admin/login");
};

export const getToken = () => {
  let token = localStorage.getItem("token");
  // return token ? token || "" : "";
  return token
    ? token || ""
    : (isAdminPath && (window.location.href = "/admin/login")) || "";
};
