import { useEffect, useState } from "react";
import "./Admins.scss";
import AdminsBody from "./AdminsBody/AdminsBody";
import AdminHeading from "./AdminHeading/AdminHeading";
import AdminHeadingButton from "./AdminHeadingButton/AdminHeadingButton";
import CreateAdminUser from "../../Overview/CreateAdminUser/CreateAdminUser";
import ViewUserModal from "../common/ViewUserModal/ViewUserModal";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "utils/api";
import { EmptyView, ErrorView, Loader } from "components/ui";
import { AdminType } from "../users.d";
import API from "utils/api/API";

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const Admins = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<AdminType>({});

  const handleViewUser = (user: AdminType) => {
    setOpenUserModal(true);
    setSelectedUser(user);
  };

  const [admins, setAdmins] = useState([]);
  const [status, setStatus] = useState(IDLE);

  const getAdmins = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getAdmins,
    })
      .then((resp) => {
        const result = resp.data.data;
        setAdmins(result);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setAdmins([]);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return <AdminsBody handleAction={handleViewUser} admins={admins} />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getAdmins} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <>
      <div className="admins">
        <div className="admins-heading">
          <div>
            <AdminHeading />
          </div>

          <div>
            <AdminHeadingButton handleOpenModal={() => setOpenModal(true)} />
          </div>
        </div>

        {renderBasedOnStatus()}
      </div>

      <CreateAdminUser isOpen={openModal} onClose={() => setOpenModal(false)} />

      <ViewUserModal
        isOpen={openUserModal}
        onClose={() => setOpenUserModal(false)}
        user={selectedUser}
        // canBeDeleted
        canBeDisabled
      />
    </>
  );
};

export default Admins;
