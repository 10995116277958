const images = {
  mobileView:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662497497/WebApp/msg777000-50514-removebg-preview_djxhtd.png",
  logo: "https://res.cloudinary.com/dtdla1h52/image/upload/v1662121138/WebApp/flyerdesign_12072021_121301_iy9fve.png",
  requestView:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662497558/WebApp/msg777000-50485-removebg-preview_nzniqb.png",
  makeUp:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662212310/WebApp/beauty-6837031_1920_fym9u4.jpg",
  laundary:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662212480/WebApp/washing-4164831_1920_wl7kbu.jpg",
  baking:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662212651/WebApp/happy-birthday-5071370_1920_xnxvqm.jpg",
  homeCleaning:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662212764/WebApp/background-2848310_1280_jf9w7l.png",
  barbing:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662212984/WebApp/hairdresser-1555599_1920_srq4bs.jpg",
  homeTutor:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662213145/WebApp/teacher-4784916_1920_vtbmdw.jpg",
  plumbing:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662213202/WebApp/copper-1189550_1280_ksifl1.jpg",
  genRepairer:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662213422/WebApp/car-engine-1738366_1920_awlqze.jpg",
  electricRepairer:
    "https://res.cloudinary.com/dtdla1h52/image/upload/v1662213489/WebApp/tool-3962800_1920_ykj0zf.jpg",
};

export default images;
