import assets from "assets/indext";
import ServiceCard from "./ServiceCard/ServiceCard";

import { useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";

const clamp = (value: number, clampAt: number = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

const categories = [
  {
    categoryName: "MAKEUP/SPA",
    image: assets.images.makeUp,
  },
  {
    categoryName: "LAUNDRY",
    image: assets.images.laundary,
  },
  {
    categoryName: "CATERING/BAKING",
    image: assets.images.baking,
  },
  {
    categoryName: "HOME CLEANING",
    image: assets.images.homeCleaning,
  },
  {
    categoryName: "BARBING/HAIR STYLING",
    image: assets.images.barbing,
  },
  {
    categoryName: "HOME TUTOR",
    image: assets.images.homeTutor,
  },
  {
    categoryName: "PLUMBING",
    image: assets.images.plumbing,
  },
  {
    categoryName: "AUTO(GENERATOR/VEHICLE) REPAIR",
    image: assets.images.genRepairer,
  },
  {
    categoryName: "ELECTRIC REPAIRS & INSTALLATION",
    image: assets.images.electricRepairer,
  },
];

let allCategories = [...categories];

const Services = () => {
  const [style, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)",
    loop: { reverse: true },
  }));

  const bind = useScroll((event) => {
    set({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`,
    });
  });
  return (
    <>
      <div className="landing-page--services" id="app-services">
        <h3 className="landing-page__title" data-aos="zoom-in">
          SERVICES
        </h3>
        <p className="landing-page__description" data-aos="zoom-in">
          The categories of on-demand home services available on our platform
          are:
        </p>
        <div
          className="landing-page--services__list"
          data-aos="zoom-in"
          {...bind()}
        >
          {allCategories.map((category, key) => (
            <ServiceCard {...category} key={key} style={style} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
