import { ReactNode } from "react";
import VisitorFooter from "./VisitorFooter/VisitorFooter";
import VisitorHeader from "./VisitorHeader/VisitorHeader";

const VisitorLayout = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <>
      <VisitorHeader />
      {children}
      <VisitorFooter />
    </>
  );
};

export default VisitorLayout;
