import AppFeatures from "./AppFeatures/AppFeatures";
// import ContactUs from "./ContactUs/ContactUs";
// import DemoVideo from "./DemoVideo/DemoVideo";
import Hero from "./Hero/Hero";
import "./LandingPage.scss";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";

const LandingPage = () => {
  return (
    <>
      <div className="landing-page">
        <span>.</span>
        <Hero />
        <Services />
        <AppFeatures />
        <Testimonials />
        {/* <DemoVideo /> */}
        {/* <ContactUs /> */}
      </div>
    </>
  );
};

export default LandingPage;
