const RequestsTableModal = (props: {
  openModal?: boolean;
  actionType?: string;
  setOpenModal?: Function;
  selectedTrans?: Object;
}) => {
  return <></>;
};

export default RequestsTableModal;
