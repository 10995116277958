import { Alert, DefaultModal } from "components/ui";
import { useState } from "react";
import { apiQueryMethods, apiUrls } from "utils/api";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { CategoryType } from "../Categories.d";
import API from "utils/api/API";

interface Props {
  isOpen: boolean;
  onClose: Function;
  getCategories?: Function;
  selectedCategory: CategoryType;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;
const DeleteCategory = (props: Props) => {
  const { isOpen, onClose, getCategories, selectedCategory } = props;
  const { categoryId, categoryName } = selectedCategory;
  const [status, setStatus] = useState(IDLE);

  const deleteCategory = (data: any) => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls.deleteCategory(categoryId || ""),
      data,
    })
      .then(() => {
        setStatus(SUCCESS);
        getCategories?.();
        onClose?.();
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  return (
    <>
      <Alert
        title=""
        text={`${categoryName} deleted succcessfully.`}
        show={status === SUCCESS ? true : false}
        timeOut={3000}
      />
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        buttonText={"Yes"}
        onButtonClick={deleteCategory}
      >
        <div>
          <h6>
            Are You Sure You Want To Delete <strong>{categoryName}</strong>?
          </h6>
        </div>
      </DefaultModal>
    </>
  );
};

export default DeleteCategory;
