import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import "./OVAnalysisChart.scss";


const OVAnalysisChart = (props: { data: Array<Object> }) => {
  const { data } = props;
  return (
    <>
      <div className="overview-analysis-chart">
        <ResponsiveContainer width={"100%"} height="100%">
          <ComposedChart width={730} height={250} data={data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#f5f5f5" />
            <Bar
              dataKey="count"
              barSize={15}
              fill="#2b5da1"
              stroke="#2b5da1"
              name="Count"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default OVAnalysisChart;
