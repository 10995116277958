import { DefaultModal } from "components/ui";
import WITHDRAWALS_ACTIONS from "../../enum";

interface Props {
  openModal: boolean;
  setOpenModal: Function;
  actionType: string;
  selectedTrans: Object;
}

const WithdrawalsModal = (props: Props) => {
  const { openModal, setOpenModal, actionType } = props;
  const renderBasedOnActionType = () => {
    switch (actionType) {
      case WITHDRAWALS_ACTIONS.VIEW_USER:
        return "";
      case WITHDRAWALS_ACTIONS.APPROVE:
        return (
          <DefaultModal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            buttonText="Yes, Approve"
          >
            <h3>Are You Sure?</h3>
          </DefaultModal>
        );
      default:
        return "";
    }
  };

  return <>{renderBasedOnActionType()}</>;
};

export default WithdrawalsModal;
