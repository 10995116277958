import { Search } from "components/ui";
import Heading from "components/ui/Heading";
import WithdrawalsTable from "./WithdrawalsTable/WithdrawalsTable";
import "./Withdrawals.scss";

const WithdrawalsHeading = () => {
  return (
    <>
      <Heading
        title="Withdrawals"
        subTitle="View & Approve Withdrawal Requests Here."
      />
      <Search
        className="withdrawals-search"
        placeholder="Search for withdrawals requests here ..."
      />
    </>
  );
};

const Withdrawals = () => {
  return (
    <>
      <div className="withdrawals">
        <WithdrawalsHeading />
        <WithdrawalsTable />
      </div>
    </>
  );
};

export default Withdrawals;
