import { Card, Icon } from "components/ui";
import "./OVSummaryCard.scss";

interface Props {
  count?: number;
  countName?: string;
  icon?: string;
}

const OVSummaryCard = (props: Props) => {
  const { countName, count } = props;
  return (
    <>
      <Card className="overview-summary-card">
        <div>
          <Icon icon="calendar" className="overview-icon" />
          <h1>{count}</h1>
          <p>{countName}</p>
        </div>
      </Card>
    </>
  );
};

export default OVSummaryCard;
