import { DropdownMenu } from "components/ui";
import TRANSACTIONS_ACTIONS from "../../enum";

const WithdrawalsTableActions = (props: { handleAction: Function }) => {
  const { handleAction } = props;

  const handleDelete = () => {
    handleAction(TRANSACTIONS_ACTIONS.APPROVE, { hi: "" });
  };
  const handleView = () => {
    handleAction(TRANSACTIONS_ACTIONS.VIEW_USER, {});
  };

  const actions = [
    { text: "Approve", handleAction: handleDelete },
    { text: "View User", handleAction: handleView },
  ];

  return <DropdownMenu menuTitle="" items={actions} />;
};

export default WithdrawalsTableActions;
