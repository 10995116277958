import { UserType } from "components/App/AdminApp/Users/users.d";
import { Card } from "components/ui";
import VUProfileActions from "./VUProfileActions/VUProfileActions";

interface Info {
  title?: string;
  value?: string;
}
const VUProfileInfoSection = (props: Info) => {
  const { title, value } = props;
  return (
    <div>
      <h6>{title}</h6>
      <p>{value}</p>
    </div>
  );
};

interface Props {
  canBeDisabled?: boolean;
  canBeDeleted?: boolean;
  user?: UserType;
}
const VUProfileInfo = (props: Props) => {
  const { user } = props;

  const userInfo = user?.user;
  return (
    <>
      <div className="view-user-profile-info">
        <Card className="view-user-profile-section">
          <div>
            <VUProfileInfoSection title="Email" value={user?.email} />
            <VUProfileInfoSection
              title="City - State - Country"
              value={
                userInfo?.country
                  ? `${userInfo?.city} ${userInfo?.state} ${userInfo?.country}`
                  : "N/A"
              }
            />

            {user?.serviceName && (
              <>
                <VUProfileInfoSection
                  title="Service Name"
                  value={user?.serviceName}
                />
                <VUProfileInfoSection title="Category" value={user?.category} />
                <VUProfileInfoSection
                  title="Description"
                  value={user?.description ? user?.description : "N/A"}
                />
              </>
            )}

            <VUProfileInfoSection
              title="Phone Number"
              value={user?.phoneNumber ? user?.phoneNumber : "N/A"}
            />

            <VUProfileInfoSection
              title="Date Joined"
              value={user?.created_at}
            />
            <VUProfileActions {...props} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default VUProfileInfo;
