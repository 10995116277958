import React from "react";
import { Route, Routes } from "react-router-dom";
import { adminRoutes, privateRoutes, publicRoutes } from "routes";

// interface RouteProps {
//   key: number;
//   subRoutes: Array<{
//     route: string;
//     component: ReactNode;
//     subTitle?: string;
//     subIcon?: string;
//   }>;
//   component: ReactNode;
//   route: string;
// }

// const RouteComponent = (props: RouteProps) => {
//   const { key, subRoutes, route, component } = props;
//   return (
//     <React.Fragment key={key}>
//       {subRoutes?.length === 0 ? (
//         <Route path={route} element={component} key={key} />
//       ) : (
//         <React.Fragment key={key}>
//           {subRoutes.map(({ route, component }, subKey) => (
//             <Route path={route} element={component} key={subKey} />
//           ))}
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// };

const PublicRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map(({ route, component, subRoutes }, key) => {
        return (
          <React.Fragment key={key}>
            {subRoutes?.length === 0 ? (
              <Route path={route} element={component} key={key} />
            ) : (
              <React.Fragment key={key}>
                {subRoutes.map(({ route, component }, subKey) => (
                  <Route path={route} element={component} key={subKey} />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Routes>
  );
};

const PrivateRoutes = () => {
  return (
    <Routes>
      {privateRoutes.map(({ route, component, subRoutes }, key) => {
        return (
          <React.Fragment key={key}>
            {subRoutes?.length === 0 ? (
              <Route path={route} element={component} key={key} />
            ) : (
              <React.Fragment key={key}>
                {subRoutes.map(({ route, component }, subKey) => (
                  <Route path={route} element={component} key={subKey} />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Routes>
  );
};

const AdminRoutes = () => {
  return (
    <Routes>
      {adminRoutes.map(({ route, component, subRoutes }, key) => {
        return (
          <React.Fragment key={key}>
            {subRoutes?.length === 0 ? (
              <Route path={route} element={component} key={key} />
            ) : (
              <React.Fragment key={key}>
                {subRoutes.map(({ route, component }, subKey) => (
                  <Route path={route} element={component} key={subKey} />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Routes>
  );
};

// const ExceptionRoutes = () => {
//   return (
//     <Routes>
//       {exceptionRoutes.map(({ route, component }, key) => {
//         return <Route path={route} element={component} key={key} />;
//       })}
//     </Routes>
//   );
// };

const AppRoutes = () => {
  return (
    <>
      <PublicRoutes />
      <PrivateRoutes />
      <AdminRoutes />
    </>
  );
};

export default AppRoutes;
