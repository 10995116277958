import { Checkbox, Table } from "components/ui";

const head = ["Title", "Permisions"];

const Permission = (props: { name: string }) => {
  return (
    <>
      <div>
        <Checkbox label={props?.name} />
      </div>
    </>
  );
};

const permissions = {
  ACCESS_TRANSACTIONS: "Access Transactions",
  ACCESS_APP_FEEDBACK: "Access App Feedback",
  APPROVE_WITHDRAWALS: "Approve Withdrawals",
  ACCESS_WITHDRAWALS: "Access Withdrawals",
  VIEW_ADMIN_USERS: "View Admin Users",
  VIEW_ALL_USERS: "View All Users",
  DISABLE_USER: "Disable User",
  DELETE_USER: "Delete User",
};

const PermissionsList = () => {
  const tableData = [
    {
      title: "Super Admin",
      permissions: (
        <div className="permissions__list">
          {Object.values(permissions).map((p: string, k: any) => (
            <Permission name={p} key={k} />
          ))}
        </div>
      ),
    },
    {
      title: "Marketing Team Admin",
      permissions: (
        <div className="permissions__list">
          <Permission name={permissions.ACCESS_TRANSACTIONS} />
          <Permission name={permissions.ACCESS_WITHDRAWALS} />
          <Permission name={permissions.APPROVE_WITHDRAWALS} />
        </div>
      ),
    },
    {
      title: "Tech Team Admin",
      permissions: (
        <div className="permissions__list">
          <Permission name={permissions.ACCESS_TRANSACTIONS} />
          <Permission name={permissions.ACCESS_WITHDRAWALS} />
          <Permission name={permissions.APPROVE_WITHDRAWALS} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table head={head} body={tableData} />
    </div>
  );
};

export default PermissionsList;
