import { Search } from "components/ui";
import Heading from "components/ui/Heading";

const AdminHeading = () => {
  return (
    <>
      <Heading title={"Admins"} subTitle={"Manage all Admins Here"} />
      <Search
        className="admins-search"
        placeholder="Search for admin users here..."
      />
    </>
  );
};
export default AdminHeading;
