import "./Layout.scss";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { LAYOUT_TYPES } from "./enum";
import UserHeader from "./Headers/UserHeader/UserHeader";

import VisitorLayout from "./Headers/VisitorLayout/VisitorLayout";
import AdminLayout from "./Headers/AdminLayout/AdminLayout";

const Layout = (props: { children?: ReactNode }) => {
  const { children } = props;

  const { pathname } = useLocation();
  const path = pathname.substring(1);

  let layoutType = LAYOUT_TYPES.VISITOR;

  if (path.includes("provider")) {
    layoutType = LAYOUT_TYPES.USER;
  }

  if (path.includes("signup") || path.includes("login")) {
    layoutType = LAYOUT_TYPES.AUTH;
  }

  if (path.includes("admin")) {
    layoutType = LAYOUT_TYPES.ADMIN;
  }

  const renderBasedOnRoute = () => {
    switch (layoutType) {
      case LAYOUT_TYPES.AUTH:
        return <div className="main-inner">{children}</div>;
      case LAYOUT_TYPES.USER:
        return (
          <>
            <main>
              <UserHeader />
              <div className="main-inner">{children}</div>
            </main>
          </>
        );
      case LAYOUT_TYPES.ADMIN:
        return (
          <>
            <AdminLayout>{children}</AdminLayout>
          </>
        );
      case LAYOUT_TYPES.VISITOR:
        return (
          <>
            <main>
              <VisitorLayout>
                <div>{children}</div>
              </VisitorLayout>
            </main>
          </>
        );
      default:
        return (
          <main>
            <VisitorLayout>
              <div>{children}</div>
            </VisitorLayout>
          </main>
        );
    }
  };

  return <>{renderBasedOnRoute()}</>;
};

export default Layout;
