import { EmptyView, ErrorView, Loader, Paginate } from "components/ui";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "utils/api";
import API from "utils/api/API";
import { dataQueryStatus } from "utils/dataQueryStatus";
import ViewUserModal from "../common/ViewUserModal/ViewUserModal";
import { CustomerType } from "../users.d";
import "./Customers.scss";
import CustomersBody from "./CustomersBody/CustomersBody";
import CustomersHeading from "./CustomersHeading/CustomersHeading";

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const Customers = () => {
  const [openUserModal, setOpenUserModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState<CustomerType>({});

  const [filter, setFilters] = useState({
    keyword: "",
    page: 1,
    limit: 5,
    filterType: undefined,
  });

  const handleViewUser = (user: CustomerType) => {
    setOpenUserModal(true);
    setSelectedUser(user);
  };

  const [customers, setCustomers] = useState([]);
  const [status, setStatus] = useState(IDLE);

  const getCustomers = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getCustomers,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        const result = resp.data.data;
        setCustomers(result);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setCustomers([]);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return (
          <CustomersBody handleAction={handleViewUser} customers={customers} />
        );
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getCustomers} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, [filter]);

  const handleSearch = (keyword: string) => {
    setTimeout(() => {
      setFilters({ ...filter, keyword });
    }, 1000);
  };

  const handleChange = (key: string, value: string) => {
    key === "keyword"
      ? handleSearch(value)
      : setFilters({
          ...filter,
          [key]: value,
        });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    const { currentPage, pageSize } = result;
    setFilters({
      ...filter,
      page: currentPage,
      limit: pageSize,
    });
  };

  return (
    <>
      <div className="customers">
        <div className="customers-heading">
          <CustomersHeading handleChange={handleChange} />
        </div>

        {renderBasedOnStatus()}

        <Paginate totalItems={10000} pageSize={5} onResult={onResult} />
      </div>

      <ViewUserModal
        isOpen={openUserModal}
        onClose={() => setOpenUserModal(false)}
        user={selectedUser}
        canBeDeleted
        canBeDisabled
      />
    </>
  );
};

export default Customers;
