import { useEffect, useState } from "react";
import CategoriesHeading from "./CategoriesHeading/CategoriesHeading";
import CategoryListing from "./CategoryListing/CategoryListing";
import CreateCategory from "./CreateCategory/CreateCategory";
import CreateCategoryButton from "./CreateCategoryButton/CreateCategoryButton";
import DeleteCategory from "./DeleteCategory/DeleteCategory";
import { EmptyView, ErrorView } from "components/ui";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "utils/api";
import "./Categories.scss";
import Loader from "components/ui/Loader";
import { CategoryType } from "./Categories.d";
import API from "utils/api/API";

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const Categories = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>({});
  const [status, setStatus] = useState(IDLE);

  const handleAction = (actionType: any, category: CategoryType) => {
    if (actionType === "DELETE_CATEGORY") {
      setIsEdit(false);
      setDeleteModal(true);
      setSelectedCategory(category);
    } else if (actionType === "EDIT_CATEGORY") {
      setIsEdit(true);
      setDeleteModal(false);
      setOpenModal(true);
      setSelectedCategory(category);
    } else {
      setIsEdit(false);
      setOpenModal(true);
      setDeleteModal(false);
    }
  };

  const getCategories = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getCategories,
    })
      .then((resp) => {
        const result = resp.data.data;
        setCategories(result);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setCategories([]);
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return (
          <CategoryListing
            handleAction={handleAction}
            categories={categories}
          />
        );
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getCategories} />;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="categories">
        <div className="categories-heading">
          <div>
            <CategoriesHeading />
          </div>
          <div>
            <CreateCategoryButton handleOpen={() => setOpenModal(true)} />
          </div>
        </div>

        {renderBasedOnStatus()}
      </div>

      <CreateCategory
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        isEdit={isEdit}
        getCategories={getCategories}
        selectedCategory={selectedCategory}
      />

      <DeleteCategory
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        selectedCategory={selectedCategory}
        getCategories={getCategories}
      />
    </>
  );
};

export default Categories;
