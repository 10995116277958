import Heading from "components/ui/Heading";
import AppFeedbackTable from "./AppFeedbackTable/AppFeedbackTable";

const AppFeedbackHeading = () => {
  return (
    <>
      <Heading title="App Feedback" subTitle="View all Feedback Received." />
    </>
  );
};
const AppFeedback = () => {
  return (
    <>
      <div className="withdrawals">
        <AppFeedbackHeading />
        <AppFeedbackTable />
      </div>
    </>
  );
};

export default AppFeedback;
