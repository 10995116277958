import { UserType } from "components/App/AdminApp/Users/users.d";
import { Loader } from "components/ui";
import "./ViewUserProfile.scss";
import VUProfileCard from "./VUProfileCard/VUProfileCard";
import VUProfileInfo from "./VUProfileInfo/VUProfileInfo";

interface Props {
  canBeDisabled?: boolean;
  canBeDeleted?: boolean;
  user?: UserType;
  loading?: boolean;
}
const ViewUserProfile = (props: Props) => {
  const { user, loading } = props;
  return (
    <>
      <div className="view-user-profile">
        {loading ? (
          <Loader />
        ) : (
          <>
            <VUProfileCard user={user} />
            <VUProfileInfo {...props} user={user} />
          </>
        )}
      </div>
    </>
  );
};
export default ViewUserProfile;
