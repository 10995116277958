import { Search } from "components/ui";
import Heading from "components/ui/Heading";
import Select from "components/ui/Select";

const CustomersHeading = (props: { handleChange: Function }) => {
  const { handleChange } = props;

  return (
    <>
      <Heading title={"Customers"} subTitle={"Manage all Customers Here"} />
      <div className="customers-heading--upper-filter">
        <Select
          options={[
            {
              label: "Choose Filter",
              value: "None",
            },
            {
              label: "First Name",
              value: "firstName",
            },
            { label: "Email", value: "email" },
            // { label: "Description", value: "description" },
            {
              label: "Phone Number",
              value: "phoneNumber",
            },
            // { label: "Description", value: "description" },
            {
              label: "Any Location Match",
              value: "location",
            },
            {
              label: "State Only",
              value: "state",
            },
            {
              label: "City Only",
              value: "city",
            },
            {
              label: "Referrals Order",
              value: "referrals",
            },
          ]}
          onChange={(value: string) => handleChange("filterType", value)}
        />
        <Search
          className="admins-search customers-heading--upper-filter--search"
          placeholder="Search for by first name, last name or email ..."
          onChange={(value: string) => handleChange("keyword", value)}
        />
      </div>
    </>
  );
};
export default CustomersHeading;
