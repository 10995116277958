import { Button } from "components/ui";
import "./AdminHeadingButton.scss";

interface Props {
  handleOpenModal: Function;
}
const AdminHeadingButton = (props: Props) => {
  const { handleOpenModal } = props;
  return (
    <>
      <div className="adminhead-button">
        <Button text="Invite Admin" onClick={handleOpenModal} />
      </div>
    </>
  );
};
export default AdminHeadingButton;

  
