import "./TermsAndConditions.scss";

const terms = [
  {
    title: "",
    texts: [],
    bulletsTitle: "A few highlights of these Terms of Service include:",
    bullets: [
      "You must be at least the legally required age in the jurisdiction in which you reside, and otherwise capable of entering into binding contracts, in order to use or access the serviceandme app.",
      "Your agreement that the technology for the serviceandme is provided “as is” and without warranty.",
      "Your agreement that ServiceandMe provides no warranty and has no liability regarding User action on the serviceandme mobile application or the performance of service.",
      "Your acknowledgment and agreement that ServiceandMe does not supervise, scope, direct, control, or monitor a service provider’s work and the services performed (Section 1).",
      "Your acknowledgement and agreement that Customers are solely responsible for determining if the service provider they hire is qualified to perform the services requested for.",
      "Your acknowledgement and agreement that service providers are independent contractors of customers and not employees of ServiceandMe.",
      "Your agreement to hold harmless and indemnify ServiceandMe from claims due to your use of or inability to use the serviceandme mobile application or content submitted from your account to ServiceandMe.",
      "ServiceandMe is not obligated to compensate service provider for Customer’s failure to pay for services.",
    ],
    bottomText: "",
  },
  {
    title:
      "ServiceandMe mobile application Connects on-demand home service providers and Customers  ",

    texts: [
      "The Term ‘users’ means both customers and service providers",
      "USERS HEREBY ACKNOWLEDGE THAT SERVICEANDME DOES NOT SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR A SERVICE PROVIDER’S WORK AND EXPRESSLY DISCLAIMS (TO THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY FOR THE WORK PERFORMED AND THE SERVICES IN ANY MANNER, INCLUDING BUT NOT LIMITED TO A WARRANTY OR CONDITION OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.",
      "Any reference on the SERVICEANDME mobile application to a service provider being licensed in some manner, or “ratings,” “review,”  “Task completed,”  (or similar language) designations indicates only that the Service provider has completed an account registration process or met certain requirements and does not represent anything else. Any such description is not an endorsement, certification or guarantee by ServiceandMe of such service provider’s skills or qualifications or whether they are licensed, insured, trustworthy, safe or suitable. Instead, any such description is intended to be useful information for Customers to evaluate when they make their own decisions about the identity and suitability of service providers whom they select, interact, or hire with via the servicandme mobile application.",
      "The Serviceandme Platform enables connections between Users for the fulfillment of  on-demand home service. ServiceandMe is not responsible for the performance or communications of Users, nor does it have control over the quality, timing, legality, failure to provide, or any other aspect whatsoever of services, service providers, or Customers, nor of the integrity, responsibility, competence, qualifications, or any of the actions or omissions whatsoever of any Users, or of any ratings or reviews provided by Users with respect to each other. ServiceandMe makes no warranties or representations about the suitability, reliability, timeliness, or accuracy of the Services requested or services provided by, or the communications of or between, Users identified through the SERVICEANDME mobile application, whether in public or private, via on- or off-line interactions, or otherwise howsoever.",
      "User Representations and Warranties",
    ],
    bulletsTitle: "All Users represent and warrant that:",
    bullets: [
      "You are at least of the legally required age in the jurisdiction in which you reside, and are otherwise capable of entering into binding contracts;",
      "You have the right, authority and capacity to enter into this Agreement and to abide by the terms and conditions of this Agreement, and that you will so abide;",
      "You have read, understand, and agree to be bound by these Terms of Service.",
      "Services shall only be performed in a country where the serviceandme mobile application has a presence.",
      "You will fulfill the commitments you make to other Users, communicate clearly and promptly through the message feature, be present and/or available at the time you agree upon with your customer or service provider as the case may be, and only utilize the payment method specified or approved by ServiceandMe to make or receive payment for services provided through the Serviceandme mobile application.",
      "You will act professionally and responsibly in your interactions with other Users;",
      "You will use your real name or service name on your profile;",
      "When using or accessing the serviceandme mobile application, you will act in accordance with all applicable local, state, provincial, national, or international law or custom and in good faith;",
      "You will not use the serviceandme mobile application for the purchase or delivery of  any controlled or illegal substances or services.",
      "Where you enter into this Agreement on behalf of a company or other organization, you represent and warrant that you have authority to act on behalf of that entity and to bind that entity to this Agreement.",
      "Other than as fully and promptly disclosed in writing to ServiceandMe, you do not have any motivation, status, or interest that ServiceandMe may reasonably wish to know about in connection with the Serviceandme mobile application, including without limitation, if you are using or will or intend to use the serviceandme mobile application for any journalistic, academic, investigative, or unlawful purpose.",
    ],
    bottomText: "",
  },
  {
    title: "Acceptable Use",
    bulletsTitle:
      "Without limitation, the serviceandme mobile application  may not be used for any of the following purposes:",
    bullets: [
      "To defame, abuse, harass, stalk, threaten, intimidate, misrepresent, mislead or otherwise violate the rights (such as, but not limited to, rights of privacy, confidentiality, reputation, and publicity) of others, including Users and ServiceandMe workers;",
      "To publish, post, upload, distribute or disseminate any profane, defamatory, infringing, obscene or unlawful language, material or information;",
      "To upload files that contain software or other material that violates the intellectual property rights (or rights of privacy or publicity) of any User, third party, or ServiceandMe;",
      "To upload files or scripts such as Trojan horses, corrupt files, SQL injections, worms, timebombs, cancelbots or any other files or software that may damage ServiceandMe or its Users’ Device;",
      "To advertise or offer to sell any goods or services for any commercial purpose through the Serviceandme mobile application  which are not relevant to the service provider’s services;",
      "To post or complete a service requiring a User to (i) purchase or obtain gift cards or money order (ii) travel into different countries during the performance of a service, (iv) post ratings or reviews on any third party website in breach of such third party website’s terms of use, or (vi) otherwise engage in activity that is illegal or deemed dangerous, harmful or otherwise inappropriate by ServiceandMe in its sole discretion;",
      "To conduct or forward surveys, contests, pyramid schemes, or chain letters;",
      "To impersonate another person or a User or allow any other person or entity to use your user profile to post or view comments.",
    ],
    texts: [],
    bottomText:
      "You understand that all submissions made to Public Areas will be public and that you will be publicly identified by your name or login identification when communicating in Public Areas. ServiceandMe will not be responsible for the action of any Users with respect to any information or materials posted in Public Areas.",
  },
  {
    title: "Mobile App Updates and Upgrades",
    texts: [
      "By installing the App(s), you consent to the installation of the App(s) and any updates or upgrades that are released through the Serviceandme Mobile application. The App (including any updates or upgrades) may (i) cause your device to automatically communicate with ServiceandMe’s servers to deliver the App functionality and to record usage metrics, (ii) affect App-related preferences or data stored on your device, and (iii) collect personal information relating to the serviceandme application. You can uninstall the App(s) at any time.        ",
    ],
    bulletsTitle: "",
    bullets: [],
    bottomText: [],
  },
  {
    title: "Deactivation and Suspension",
    texts: [
      "ServiceandMe may suspend your right to use the serviceandme mobile application pending its investigation of a potential breach by you of this Agreement. ServiceandMe may deactivate your account or limit your use of the serviceandme Platform upon its determination that you breached a provision of this Agreement (a “User Breach” ). Serviceandme will provide you with written notice of its determination as required by law, unless we have reason to believe the User’s account has been compromised such that the notice would go to the wrong person, or notice would otherwise be counterproductive or would create a risk to safety.",
      "If ServiceandMe suspends or deactivates your account or limits your use of the serviceandme mobile application, you are thereafter prohibited from registering and creating a new account under your name or business name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.",
      "Even after your right to use the serviceandme mobile application is suspended, terminated or limited, this Agreement will remain enforceable against you. ServiceandMe reserves the right to take appropriate legal action pursuant to the Agreement.",
      "ServiceandMe reserves the right to modify or discontinue, temporarily or permanently, all or any portion of the serviceandme mobile application at its sole discretion. ServiceandMe will provide you with notice of any such modification or discontinuation as required by law.",
      "You may terminate this Agreement at any time by ceasing all use of the serviceandme mobile application and deactivating your account.",
    ],
    bottomText: "",
    bulletsTitle: "",
    bullets: [],
  },
  {
    title: "Intellectual Property Rights",
    texts: [
      "All text, graphics, editorial content, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, typefaces and other content, including ServiceandMe designs, trademarks, and logos (collectively “Proprietary Material” ) that Users see or read through the Serviceandme mobile application is owned by ServiceandMe, excluding User Generated Content.",
      "ServiceandMe owns all Proprietary Material, as well as the coordination, selection, arrangement and enhancement of such Proprietary Materials. The Proprietary Material is protected by domestic and international laws governing copyright, patents, and other proprietary rights. Users may not copy, download, use, redesign, reconfigure, or retransmit anything from the serviceandme Platform without serviceandme’s express prior written consent and, if applicable, the consent of the holder of the rights to the User Generated Content. Any use of such Proprietary Material other than as permitted herein is expressly prohibited.",
      "The Use Of The ServiceandMe mobile application Is Entirely At Your Own Risk",
    ],
    bottomText: "",
    bulletsTitle: "",
    bullets: [],
  },
];

const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-page">
        <span>.</span>
        <h1 data-aos="zoom-in">Our Terms and Conditions</h1>
        <div className="terms-page__content">
          <p>
            These Terms of Service constitute a legally binding agreement
            between you and serviceandme governing your use of the serviceandme
            mobile application.
          </p>
          <p>
            Your consent at registration and continued use of serviceandme
            mobile app constitutes your acceptance of and agreement to all of
            the terms and conditions in these Terms of Service, as well as any
            future amendments and additions to this Agreement (as defined below)
            we may publish from time to time. If any future changes to this
            Agreement are unacceptable to you or cause you to no longer be in
            compliance with this Agreement, you must deactivate your account,
            and immediately stop using serviceandme mobile application.
          </p>
          {terms.map(
            ({ title, texts, bulletsTitle, bullets, bottomText }, key) => (
              <div key={key} className="terms-page__content--body">
                <h4>{title}</h4>

                {texts.map((eachText, textKey) => (
                  <p key={textKey}>{eachText}</p>
                ))}

                <p>{bulletsTitle}</p>
                {bullets.map((eachText, textKey) => (
                  <li key={textKey}>{eachText}</li>
                ))}

                <p>{bottomText}</p>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
