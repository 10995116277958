import { Col, Row } from "react-bootstrap";
import { Button } from "components/ui";
interface Props {
  canBeDisabled?: boolean;
  canBeDeleted?: boolean;
}
const VUProfileActions = (props: Props) => {
  const { canBeDisabled, canBeDeleted } = props;
  return (
    <>
      <div className="view-user-profile-actions">
        <Row>
          {canBeDisabled && (
            <Col>
              <Button
                text="Disable User"
                className="view-user-profile__disable_btn"
                invertStyle
              />
            </Col>
          )}
          {canBeDeleted && (
            <Col>
              <Button
                text="Delete User"
                className="view-user-profile__delete_btn"
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default VUProfileActions;
