import { useState } from "react";
import { SideModal } from "components/ui";
import CreateCategoryForm from "./CreateCategoryForm/CreateCategoryForm";
import { CategoryType } from "../Categories.d";

interface Props {
  isOpen: boolean;
  onClose: Function;
  isEdit?: boolean;
  getCategories?: Function;
  selectedCategory: CategoryType;
}
const CreateCategory = (props: Props) => {
  const { isOpen, onClose, isEdit, getCategories, selectedCategory } = props;

  const [response, setResponse] = useState({
    message: "",
    status: "",
  });

  const updateApiResponse = (resp: { message: any; status: string }) => {
    setResponse(resp);
  };

  const handleClose = () => {
    setResponse({
      message: "",
      status: "",
    });
    onClose?.();
  };

  return (
    <>
      <SideModal
        title={isEdit ? "Update Category" : "Create New Category"}
        subTitle={
          isEdit ? "Update A Category Here." : "Add A New CategorY Here."
        }
        isOpen={isOpen}
        onClose={handleClose}
        message={response?.message}
        status={response?.status}
      >
        <CreateCategoryForm
          getCategories={getCategories}
          updateApiResponse={updateApiResponse}
          categoryId={selectedCategory?.categoryId}
          isEdit={isEdit}
          categoryName={selectedCategory?.categoryName}
          onClose={onClose}
        />
      </SideModal>
    </>
  );
};

export default CreateCategory;
