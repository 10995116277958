import { Button, Input } from "components/ui";

import { useForm } from "react-hook-form";

import { useState } from "react";
import { dataQueryStatus } from "utils/dataQueryStatus";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const CreateAdminUserForm = () => {
  const [status, setStatus] = useState(IDLE);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("resp", data);
    setStatus(LOADING);
    setStatus(SUCCESS);
    setStatus(ERROR);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          inputClass="create-admin-input"
          placeholder="Type The Admin's Email address."
          hasError={errors?.email ? true : false}
          name="email"
          register={register}
          required
        />
        <Input
          label="Password"
          inputClass="create-admin-input"
          placeholder="Generate A Password for them."
          hasError={errors?.password ? true : false}
          name="password"
          register={register}
          required
        />
        <Button text="Invite" className="create-admin-button" status={status} />
      </form>
    </>
  );
};

export default CreateAdminUserForm;
