import { Col } from "react-bootstrap";
import "./PersonalDetails.scss";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import PersonalPicture from "./PersonalPicture/PersonalPicture";

const PersonalDetails = () => {
  return (
    <div className="personal-details">
      <Col xl={6} md={6}>
        <PersonalPicture />
        <PersonalInfo />
      </Col>
    </div>
  );
};

export default PersonalDetails;
