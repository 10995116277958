import { Button, Card } from "components/ui";
import "./CategoryCard.scss";

interface Props {
  category: string;
  _id: string;
  handleAction: Function;
  totalRenderedServices: number;
}
const CategoryCard = (props: Props) => {
  const { handleAction, category, totalRenderedServices, _id } = props;

  const newlySelectedCategory = { categoryName: category, categoryId: _id };

  return (
    <>
      <Card className="category-card">
        <div>
          <div>
            <h1>{category}</h1>
            <p>{totalRenderedServices} total services rendered</p>
          </div>
          <div className="category-card-buttons">
            <Button
              text="Edit"
              className="category-card-buttons__edit"
              onClick={() =>
                handleAction("EDIT_CATEGORY", newlySelectedCategory)
              }
              invertStyle
            />
            <Button
              text="Delete"
              className="category-card-buttons__delete"
              onClick={() =>
                handleAction("DELETE_CATEGORY", newlySelectedCategory)
              }
            />
          </div>
        </div>
      </Card>
    </>
  );
};
export default CategoryCard;
