import Heading from "components/ui/Heading";
import OVAnalysisChart from "./OVAnalysisChart/OVAnalysisChart";

const OVAnalysis = (props: {
  title: string;
  subTitle: string;
  data: Array<Object>;
}) => {
  const { title, subTitle, data } = props;
  return (
    <>
      <div>
        <div>
          <Heading title={title} subTitle={subTitle} />
        </div>
        <div>
          <OVAnalysisChart data={data} />
        </div>
      </div>
    </>
  );
};

export default OVAnalysis;
