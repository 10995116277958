import "./PrivacyPage.scss";

const privacyPolicies = [
  {
    title: "1. General Terms",
    texts: [
      "ServiceandMe collects certain personally identifiable information about you, including information that can identify, relate to, describe, be associated with you, or is reasonably capable of being associated with you (“Personal Information”). Examples of Personal Information that ServiceandMe  collects include but are not limited to:",
    ],
  },
  {
    title: "2. Information Collection",
    texts: [
      "Contact Information. This may include your first and last name, postal address, phone number, and email address.",
      "Billing Data, including your payment instrument number (such as a credit or debit card number), expiration date, and security code as necessary to process your payments.",
      "Identity Information. If you are a Service provider, we may collect Personal Information, such as your date of birth and address, as well as such information as may be needed to validate your identity.",
      "Financial Information. To help Service providers set up a payment account and help Customers make payments to Service providers and pay fees to ServiceandMe , we may collect financial information, including card number, bank account details, and other payment information, as applicable. We use Financial Information in order to operate the ServiceandMe  mobile app and to ensure that Service providers are paid by Customers. We do this as necessary for our legitimate interests in providing our platform and services and to fulfill our contracts with Users. To keep your financial data secure, we have contracted with a third party to maintain and process your payment information.",
      "Promotional Information. Certain offerings of the ServiceandMe  Platform, such as newsletters, surveys, contests, and the like are optional and so you are not required to enter them or to give us your data in connection with them. Where you do consent to take advantage of ServiceandMe  Platform offerings, we will use your data to (as applicable) send you newsletters and other communications that are tailored based on information we have about you, or to operate and manage the survey, contest or similar offering in connection with our legitimate interest in promoting our business and the ServiceandMe  Mobile app. You can always opt-out of receiving marketing communications from us.",
      "Content Information. You also may choose to send ServiceandMe  Personal Information in an email or chat message containing inquiries about the ServiceandMe  Platform and we use this Information in order to help us respond to your inquiry. We also collect content within any messages you exchange with other Users through the ServiceandMe  Mobile app (such as through our chat functionality).",
      "We require that you furnish certain information when you register an account with us in order to provide services through the ServiceandMe  Mobile app. For example, if you are a Customer, we collect your first and last name, email address, and your location in order to create and administer your ServiceandMe  account. We also collect additional information in order to facilitate your  request, such as information about the service you are seeking, the time, date and location of the service, and Billing Data. If you are a Service provider, we collect your first and last name, email address, mobile phone number and location in order to create and administer your ServiceandMe  account and facilitate communications between you and your Customers through the ServiceandMe  Mobile app. We also collect information about your services, rates, and skills, as well as Identity Information and Financial Information.",
    ],
  },
  {
    title: "3. Information ServiceandMe  Collects Automatically",
    texts: [
      "We automatically collect certain information when you use the ServiceandMe  Platform. The categories of information we automatically collect  are as follows:",
      "Service Use Data, including data about features you use, app pages you visit, emails and advertisements you view, portions of the ServiceandMe  Mobile app that you view and interact with, the time of day you browse, and your referring and exiting pages.",
      "Device Data, including data about the type of device or browser you use, your device’s operating system, your internet service provider, your device’s regional and language settings, and device identifiers such as IP address and Ad Id. When you visit and interact with the ServiceandMe  Platform(Mobile app and website), ServiceandMe  may collect certain information automatically through cookies or other technologies, including, but not limited to, the type of computer or mobile device you use, your mobile device’s unique device ID, the IP address of your computer or mobile device, your operating system, the type(s) of internet browser(s) you use, and information about the way you use the ServiceandMe  Platform (“Device Information”). We may use Device Information to monitor the geographic regions from which Users navigate the ServiceandMe  Platform, and for security and fraud prevention purposes.",
      "Use of any IP-masking technologies or similar technologies (like the TOR network) may render portions of the ServiceandMe  Platform unavailable and are forbidden on the ServiceandMe  Platform.",
      "Location Data, including imprecise location data (such as location derived from an IP address or data that indicates a city or postal code level), and, with your consent, precise location data (such as latitude/longitude data). When you visit the ServiceandMe  Platform via a native mobile application, we use, with your consent when required under applicable law, GPS technology (or other similar technology) to determine your current location in order to determine the city you are located in and display a relevant location map. We will not share your current location obtained in this manner with other Users.",
      "Cookies and similar technologies are described in our Cookie Policy, which sets out the different categories of cookies and similar technologies that the ServiceandMe  Platform uses and why we use them.",
    ],
  },
  {
    title: "4. ServiceandMe ’s Use of Information",
    texts: [
      "We collect and use information for business and commercial purposes in accordance with the practices described in this Privacy Policy. Our business purposes for collecting and using information include:",
      "To operate and make available the ServiceandMe  Platform. We use your data to connect you with other Users to enable the posting of, selection for, completion of, and payment for Services, in order to fulfill our contracts with Users;",
      "For billing and fraud prevention, on the basis of our legitimate interests in ensuring a safe and secure environment in which Customers and Service providers can meet and conduct business, and in order to comply with our legal obligations;",
      "To conduct identification and  background checks, if applicable and to the extent permitted by local law, on Users, in order to advance our legitimate interests as well as for the substantial public interest of ensuring the safety of our Users both online and offline, preventing or detecting unlawful acts, protecting the public against dishonesty, and maintaining the integrity of the ServiceandMe  Mobile app given that Service providers often interact directly with Customers and may enter their homes;",
      "To analyze ServiceandMe  Platform usage as necessary for our legitimate interest in improving the ServiceandMe  Platform to grow our business;",
      "To contact you and deliver (via email, SMS, push notifications, or otherwise) transactional information, administrative notices, marketing notifications, offers and communications relevant to your use of the ServiceandMe  Platform, with your consent when required under applicable law, as necessary for our legitimate interests in proper communication and engagement with our Users, and in promoting our business;",
      "To provide you with customer support in order to fulfill our contracts with Users;",
      "For internal market research for our legitimate interest in improving the ServiceandMe  Platform to grow our business;",
      "For troubleshooting problems for our legitimate interests in ensuring a safe and secure environment in which Users can meet;",
      "To assist Users in the resolution of complaints and disputes between them, as necessary for our legitimate interests in facilitating good relations among Users;",
      "To enforce our Terms of Service and our legitimate interests in ensuring our Agreement is complied with; and",
      "As otherwise set forth in the Agreement.",
      "Interest-Based Advertising. Ads are a standard part of user experience on the Internet, and ServiceandMe  believes that targeted advertising enhances this experience. ServiceandMe  and affiliate third parties may use cookies and other technologies to place ads where they believe interested Users will see them. In addition to banner ads, ServiceandMe  may advertise products, companies and events that we think might interest you through the email address and/or phone number you provide. We may incorporate Tracking Technologies on the ServiceandMe  Platform (including our website and emails) as well as into our ads displayed on other websites and services. Some of these Tracking Technologies may track your activities across time and services for purposes of associating the different devices you use, and delivering relevant ads and/or other content to you (“Interest-Based Advertising”).",
      "Advertising and marketing is carried out as necessary for our legitimate interests in providing an engaging and relevant experience, promoting our services, and growing our business.",
      "Analytics and Market Analysis. ServiceandMe  may analyze information (“Market Analysis”) as necessary for our legitimate interests in providing an engaging and relevant experience, and promoting and growing the ServiceandMe  Platform.",
      "ServiceandMe  uses information to offer services to Users who express an interest in these services, through a poll for example, or to Users who can be presumed to have an interest based on results from our Market Analysis. We do this as necessary for our legitimate interests in providing an engaging and relevant experience, promoting our services, and growing our business.",
      "Cell Phone Numbers. ServiceandMe  may use your cell phone number to call or send recurring text messages to you, using an autodialer or prerecorded voice, in order to provide you notifications about Services, for marketing purposes (with your consent where required by law), and to administer the ServiceandMe  Platform. You may be liable for standard SMS and per-minute charges by your mobile carrier. ServiceandMe  may also message you via push notifications (with your consent when required under applicable law), which you can opt-out of on your mobile device. Data rates may apply.",
      "Call recordings. Calls to or from ServiceandMe  or its Third Party Agents may be monitored and recorded for the purposes of quality control and training. Such calls may contain Personal Information.",
    ],
  },
  {
    title: "5. Information Sharing",
    texts: [
      "We only share the Information we collect about you as described in this Privacy Policy or as described at the time of collection or sharing, including as follows:",
      "Third Party Agents. We share information, including Identity Information, with entities that process information on our behalf for our business purposes. We contractually prohibit our Third-Party Agents from retaining, using, or disclosing information about you for any purposes other than performing the services for us, although we may permit them to use information that does not identify you (including information that has been aggregated or de-identified) for any purpose except as prohibited by applicable law.",
      "To operate the ServiceandMe  Platform, including processing your transactions, we may share your Personal Information with our agents, representatives, vendors and service providers (“Third Party Agents”) so they can provide us with support services as follows:",
      "Receipt, invoice, or support services;",
      "Customer relationship management services;",
      "Data analytics;",
      "Marketing and advertising;",
      "Website hosting;",
      "Communications services;",
      "Technical support;",
      "Financial transaction fulfillment (in which we are currently supported by Paystack, who processes your Personal Information in accordance with its own privacy policy) and related chargeback and collection services; and",
      "To otherwise help us provide the ServiceandMe  Platform.",
      "Other Users. ServiceandMe  facilitates contact between Customers and Service providers and reserves the right to share one User’s contact information (e.g., name, phone number, email, or postal address) with other Users, or with the recipient User’s legal or other authorized representative, in order to: (1) facilitate or communicate the resolution of an investigation or dispute related to or arising from an interaction between two or more Users of the ServiceandMe  Platform; or (2) facilitate the performance of a Service. This exchange of information is essential to the operation of the ServiceandMe  Platform.",
      "Legal Obligations. ServiceandMe  and our Third Party Agents may disclose your Personal Information or User Generated Content to courts, law enforcement, governmental or public authorities, tax authorities or authorised third parties, if and to the extent required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with ServiceandMe ’s legal or regulatory obligations; (ii) respond to a court order, warrant or subpoena; (iii) respond to a valid legal request relating to an investigation into alleged criminal or illegal activity; or (iv) respond to or address any other activity that may expose us to legal or regulatory liability.",
      "In jurisdictions where ServiceandMe  is, according to applicable law, required to collect or remit information about Users’ permits, licences, or registration details, ServiceandMe  and our Third Party Agents may disclose information, including but not limited to, User contact details, identification information, transaction dates and amounts, license and permit status to applicable governmental authorities.",
      "ServiceandMe  reserves the right to disclose Personal Information from both private and public areas of the ServiceandMe  Platform in the absence of a court order, warrant, or subpoena, to the extent permitted by applicable law, if we are given reason to believe, in our sole discretion, that someone is causing injury to or interfering with the rights of Users, the general public, or ServiceandMe  or its partners, parents or affiliates.",
      "It is our policy to provide notice to Users before producing their information in response to law enforcement requests unless (i) we are prohibited or otherwise constrained by law or court order from doing so, (ii) we have reason to believe the User’s account has been compromised such that the notice would go to the wrong person, or notice would otherwise be counterproductive or would create a risk to safety, or (iii) it is an emergency request and prior notice would be impractical (in which case we may provide notice after the fact).",
      "Merger or Acquisition. ServiceandMe  reserves the right to share information in connection with, or during negotiations of, any proposed or actual merger, purchase, sale, or any other type of acquisition or business combination of all or any portion of our assets, or transfer of all or a portion of our business to another business.",
      "Public Areas. Your profile on the ServiceandMe  Platform consists of information about you and your business, and may include information such as photographs, your years in business, skills and expertise, hourly pay rates, feedback/rating information, and other information, including your username (“Profile”). The information in your User Profile may be visible to all Users and the general public.",
    ],
  },
  {
    title: "6. Your Rights and Choices",
    texts: [
      "You may opt-out of receiving promotional communications from us, including promotional communications related to the ServiceandMe  Platform, and request the removal of your Personal Information from our databases, or deactivate your account, by logging on to the  App and clicking on the “Account” tab, or by contacting us.",
      "Push Notifications. You have the option to receive updates and relevant offers via push notifications in your app. These notifications can be configured in the settings of your mobile device at any time.",
      "Corrections to Profile. You have the right to access, update, and correct inaccuracies in your ServiceandMe  Profile at any time by logging in and clicking on the “Account” tab. There, you can view, update, and correct your Account information.",
      "So that we may protect the integrity of the ServiceandMe  Platform, there are certain pieces of information, such as your age, that you cannot alter yourself. For example, since children under the age of majority in their jurisdiction of residence are not allowed to register as Users, we need to take reasonable measures to preserve the accuracy of our Users’ ages. You may contact us at privacy@ServiceandMe .com if there is a need to make a correction to such data.",
      "Promotional Communications. You can opt out of receiving promotional communications from ServiceandMe  via the Account tab in your Profile. In addition, you can opt out of email marketing by clicking on the unsubscribe link in any message. You can opt out of promotional communications from ServiceandMe  sent via text message at any time by following the instructions provided in those messages to text the word “STOP”. Please note that your opt-out is limited to the phone number used and will not affect subsequent subscriptions. If you opt-out of only certain communications, other subscription communications may continue. Even if you opt-out of receiving promotional communications, ServiceandMe  will continue to send you non-promotional communications, such as those about your account, Services, transactions, servicing, or ServiceandMe ’s ongoing business relationship with you. If you receive unsolicited promotional communications from a ServiceandMe  domain, please let us know here.",
    ],
  },
  {
    title: "7. ServiceandMe ’s Information Retention Policy",
    texts: [
      "We retain personal data for as long as you are a User in order to meet our contractual obligations to you, and for such longer period as may be in our legitimate interests and to comply with our legal obligations  We may also retain data from which you cannot directly be identified, for example where stored against a randomly-generated identifier so we know the data relates to a single User, but we cannot tell who that User is. We use this kind of data for research purposes and to help us develop and improve our services, and we take appropriate measures to ensure you cannot be re-identified from this data.",
    ],
  },
  {
    title: "8. ServiceandMe ’s Security of Collected Information",
    texts: [
      "Your ServiceandMe  account is password-protected so that only you and authorized ServiceandMe  staff have access to your account information. In order to maintain this protection, do not give your password to anyone. Also, if you share a device, you should sign out of your ServiceandMe  account and close the application before someone else logs on. This will help protect your information entered on public terminals from disclosure to third parties.",
      "ServiceandMe  implements and maintains reasonable administrative, physical, and technical security safeguards to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. ServiceandMe  has staff dedicated to maintaining this Privacy Policy and other privacy initiatives, periodically reviewing security, and making sure that every ServiceandMe  employee is aware of our security practices. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you.",
    ],
  },
  {
    title: "9. Notification of Changes",
    texts: [
      "ServiceandMe ’s Privacy Policy is periodically reviewed and enhanced as necessary. This Privacy Policy might change as ServiceandMe  updates and expands the ServiceandMe  Platform. ServiceandMe  will endeavor to notify you of any material changes by email. ServiceandMe  also encourages you to review this Privacy Policy periodically.",
    ],
  },
];

const PrivacyPage = () => {
  return (
    <>
      <div className="privacy-page">
        <span>.</span>
        <h1 data-aos="zoom-in">Our Privacy Policy</h1>
        <div className="privacy-page__content">
          <p>
            By using the ServiceandMe Mobile app, you confirm that you have read
            and understand this Privacy Policy, and our Terms of Service
            (together referred to herein as the “Agreement”). The Agreement
            governs the use of the serviceandme mobile app. ServiceandMe will
            collect, use, and maintain information consistent with the
            Agreement.
          </p>
          {privacyPolicies.map(({ title, texts }, key) => (
            <div key={key}>
              <h4>{title}</h4>
              {texts.map((eachText, textKey) => (
                <p key={textKey}>{eachText}</p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
