import { useEffect, useState } from "react";
import { EmptyView, ErrorView, Loader, Paginate, Table } from "components/ui";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "utils/api";
import { getFormattedDate } from "utils/helper";
import RequestsTableModal from "./RequestsTableModal/RequestsTableModal";
import RequestsTableActions from "./RequestsTableActions/RequestsTableActions";
import ViewUserModal from "../../Users/common/ViewUserModal/ViewUserModal";
import API from "utils/api/API";

const head = [
  "Opened By",
  "(To) Be Rendered By",
  "No Of Bids",
  "Status",
  "Location",
  "Description",
  "Currency",
  "Category",
  "Date",
  "Action",
];

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const RequestsTable = () => {
  const [selectedUser, setSelectedUser] = useState({
    initiator: {},
    serviceRenderer: "",
  });

  const [status, setStatus] = useState(IDLE);
  const [requests, setRequests] = useState([]);
  const [filter, updateFilter] = useState({
    limit: 10,
    page: 1,
  });

  const [action, setAction] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleAction = (selectedUserIds: {
    initiator: {
      email?: string;
      phoneNumber?: string;
    };
    serviceRenderer: string;
  }) => {
    setOpenModal(true);
    setSelectedUser({
      initiator: selectedUserIds?.initiator,
      serviceRenderer: selectedUserIds?.serviceRenderer,
    });
    setAction("");
  };

  const getRequests = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,

      url: apiUrls?.getRequests,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        const result = resp.data.data;

        const formattedList = result?.map((eachRequest: any) => {
          const {
            initiator,
            serviceRenderer,
            status,
            city,
            state,
            country,
            description,
            currency,
            category,
            created_at,
            noOfBids,
            _id,
          } = eachRequest;

          return {
            initiator: `${initiator?.firstName} ${initiator?.lastName}`,
            serviceRenderer: serviceRenderer?.serviceName || "None",
            noOfBids,
            status,
            location: `${city + " " + state + " " + country}`,
            description,
            currency,
            category,
            date: <div>{getFormattedDate(created_at)}</div>,
            action: (
              <RequestsTableActions
                handleAction={handleAction}
                {...{
                  _id,
                  initiator,
                  serviceRenderer,
                }}
              />
            ),
          };
        });

        setRequests(formattedList);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setRequests([]);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return (
          <>
            <Table
              head={head}
              body={requests}
              tableClass="admin-requests--table"
            />
            <Paginate
              totalItems={100}
              pageSize={filter?.limit}
              currentPage={filter?.page}
              onResult={onResult}
            />
          </>
        );
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getRequests} />;
      default:
        return "";
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
  };

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      {renderBasedOnStatus()}
      <RequestsTableModal
        openModal={openModal}
        actionType={action}
        setOpenModal={setOpenModal}
      />

      {openModal && (
        <ViewUserModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          user={{}}
          loadFromServer={true}
          initiator={selectedUser?.initiator}
          serviceRenderer={selectedUser?.serviceRenderer}
        />
      )}
    </>
  );
};

export default RequestsTable;
