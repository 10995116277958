import Heading from "components/ui/Heading";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import SettingsButton from "./SettingsButton/SettingsButton";
import "./Settings.scss";
import { useState } from "react";
import ChangeMyPassword from "./ChangeMyPassword/ChangeMyPassword";

const AdminSettings = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="settings">
        <div className="settings-heading">
          <div>
            <Heading
              title="Settings"
              subTitle="Manage Your Personal Settings Here"
            />
          </div>
          <div>
            <SettingsButton handleOpen={() => setOpenModal(true)} />
          </div>
        </div>
        <div>
          <PersonalDetails />
        </div>
      </div>

      <ChangeMyPassword
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default AdminSettings;
