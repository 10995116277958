import { Icon } from "components/ui";

const TestimonialsCard = (props: { name: string; text: string }) => {
  const { name, text } = props;

  return (
    <div className="testimonials__card">
      <div>
        <Icon icon="user" className="testimonials__card--user" />
        <h4>{name}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default TestimonialsCard;
