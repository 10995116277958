import { useAppContext } from "AppContext";
import Icon from "components/ui/Icon";

const AdminLayoutHeader = (props: { open: boolean; toggleSidebar: Function }) => {
  const { toggleSidebar, open } = props;
  const { user } = useAppContext();
  const { firstName, lastName } = user;

  return (
    <>
      <header>
        {open ? (
          <div className="header-menu">
            <div>
              <div>
                <Icon icon="avatar" className="avatar" />
                <span className="header-menu-user">
                  {firstName + " " + lastName}
                </span>
              </div>
              <div onClick={() => toggleSidebar()} className="menu">
                <Icon icon="close" className="menu-open" />
              </div>
            </div>
          </div>
        ) : (
          <div className="header-menu-close" onClick={() => toggleSidebar()}>
            <Icon icon="menu" className="menu-open" />
          </div>
        )}
      </header>
    </>
  );
};

export default AdminLayoutHeader;
