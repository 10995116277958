import { UserType } from "components/App/AdminApp/Users/users.d";
import { Card, Icon } from "components/ui";

const VUProfileCard = (props: { user?: UserType }) => {
  return (
    <>
      <div>
        <Card className="view-user-profile-card">
          <Icon
            icon="user"
            className="view-user-profile-card-icon"
            src={props?.user?.profilePhoto}
          />
        </Card>
      </div>
    </>
  );
};

export default VUProfileCard;
