import { Button, Card, Icon } from "components/ui";
import { UserType } from "../../users.d";
import "./UserCard.scss";

interface Props {
  handleAction: Function;
  user: UserType;
}

const UserCard = (props: Props) => {
  const { handleAction, user } = props;

  const { firstName, lastName, email, serviceName, profilePhoto } = user;
  return (
    <>
      <Card className="user-card">
        <div className="user-card__image">
          <div className="user-card__image-container">
            <Icon
              icon="avatar"
              className="user-card__image-avatar"
              src={profilePhoto}
            />
          </div>
        </div>

        <div>
          <h1>
            {serviceName ? serviceName : <>{firstName + " " + lastName} </>}
            <span className="user-indicator">{user?.user?.userType}</span>
          </h1>
          <p>{email} </p>
        </div>
        <div>
          <Button
            text="VIEW PROFILE"
            onClick={() => handleAction(user)}
            invertStyle
          />
        </div>
      </Card>
    </>
  );
};
export default UserCard;
