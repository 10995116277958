import { Search } from "components/ui";
import Heading from "components/ui/Heading";
import TransactionsTable from "./TransactionsTable/TransactionsTable";
import "./Transactions.scss";

const TransactionsHeading = () => {
  return (
    <>
      <Heading
        title="Transactions"
        subTitle="Manage Inflows and Outflows Here."
      />
      <Search
        className="transactions-search"
        placeholder="Search for transactions here ..."
      />
    </>
  );
};

const Transactions = () => {
  return (
    <>
      <div className="transactions">
        <TransactionsHeading />
        <TransactionsTable />
      </div>
    </>
  );
};

export default Transactions;
