import { Col, Row } from "react-bootstrap";
import CategoryCard from "./CategoryCard/CategoryCard";

interface Props {
  handleAction: Function;
  categories: Array<{
    category: string;
    _id: string;
    totalRenderedServices: number;
  }>;
}

const CategoryListing = (props: Props) => {
  const { handleAction, categories } = props;
  return (
    <>
      <div>
        <Row>
          {categories.map((item, key) => (
            <Col key={key} xl={4} md={6} xs={12} sm={12}>
              <CategoryCard handleAction={handleAction} {...item} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default CategoryListing;
