import { Col, Row } from "react-bootstrap";
import { CustomerType } from "../../users.d";
import CustomersUserCard from "./CustomersUserCard/CustomersUserCard";

interface Props {
  handleAction: Function;
  customers: Array<CustomerType>;
}

const CustomersBody = (props: Props) => {
  const { handleAction, customers } = props;
  return (
    <>
      <div>
        <Row>
          {customers.map((item, key) => (
            <Col key={key} xl={3}>
              <CustomersUserCard handleAction={handleAction} user={item} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default CustomersBody;
