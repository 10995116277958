import { Button } from "components/ui";
interface Props {
  handleOpen: Function;
}
const SettingsButton = (props: Props) => {
  const { handleOpen } = props;
  return (
    <>
      <Button text="Change Password" onClick={handleOpen} />
    </>
  );
};

export default SettingsButton;
