import { Col, Row } from "react-bootstrap";
import { ProviderType } from "../../users.d";
import ServiceProvidersUserCard from "./ServiceProvidersUserCard/ServiceProvidersUserCard";

interface Props {
  handleAction: Function;
  providers: Array<ProviderType>;
}

const ServiceProvidersBody = (props: Props) => {
  const { handleAction, providers } = props;
  return (
    <>
      <div>
        <Row>
          {providers.map((item, key) => (
            <Col key={key} xl={3}>
              <ServiceProvidersUserCard
                handleAction={handleAction}
                user={item}
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ServiceProvidersBody;
