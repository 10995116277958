import { Icon } from "components/ui";
import { Col, Row } from "react-bootstrap";

interface Props {
  title: string;
  text: string;
}

const Feature = (props: Props) => {
  const { title, text } = props;
  return (
    <div className="app-feature" data-aos="zoom-in">
      <div className="app-feature__inner">
        <Icon icon="dashboard" className="features-icon" />
        <div>
          <h4>{title}</h4>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

const features = [
  {
    title: "Post and Feed system",
    text: "As a service provider, you can create a post(News/Trends/promo) that would be visible to all customers within 10km away, This gives you cheap, fast, and easy marketing.",
  },
  {
    title: "Flexible Payment Options",
    text: "Payment by card, Bank Transfer, etc are accepted.",
  },

  {
    title: "Easy-to-use Platform.",
    text: "With a few clicks, you get to request for any services or offer your service.",
  },
  {
    title: "Geolocation Enabled System.",
    text: "Request services which are available around you or offer service to customers in your vicinity.",
  },
  {
    title: "Superapp Concept & Competitive Pricing.",
    text: "One app for many services.",
  },
];

const AppFeatures = () => {
  return (
    <div className="landing-page--features" id="app-features">
      <h3 className="landing-page__title" data-aos="zoom-in">
        FEATURES
      </h3>
      <p className="landing-page__description" data-aos="zoom-in">
        Our unique features are
      </p>
      <div className="landing-page--features__list">
        <Row>
          {features.map((feature, key) => (
            <Col className="app-feature-col" xs={12} sm={6} key={key}>
              <Feature {...feature} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default AppFeatures;
