import Heading from "components/ui/Heading";
import PermissionsList from "./PermissionsList/PermissionsList";
import "./Permissions.scss";

const PermissionsHeading = () => {
  return (
    <>
      <Heading title="App Permissions" subTitle="Manage permissions here." />
    </>
  );
};

const Permissions = () => {
  return (
    <>
      <div className="permissions">
        <PermissionsHeading />
        <PermissionsList />
      </div>
    </>
  );
};

export default Permissions;
