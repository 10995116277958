import { useEffect, useState } from "react";

import CreateAdminUser from "./CreateAdminUser/CreateAdminUser";
// import OVAnalysis from "./OVAnalysis/OVAnalysis";
import OVSummary from "./OVSummary/OVSummary";
import OVSummaryButton from "./OVSummaryButton/OVSummaryButton";
import "./Overview.scss";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "utils/api";

import { ErrorView, Loader } from "components/ui";
import OVAnalysis from "./OVAnalysis/OVAnalysis";
import API from "utils/api/API";

const { IDLE, SUCCESS, ERROR, LOADING } = dataQueryStatus;

const AdminOverview = () => {
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(IDLE);

  const [statData, setStatData] = useState([{}]);

  const [customersStat, setCustomersStat] = useState([]);
  const [providersStat, setProvidersStat] = useState([]);
  const [requestsStat, setRequestsStat] = useState([]);
  const [searchesStat, setSearchesStat] = useState([]);
  const [transStat, setTransStat] = useState([]);

  // const [statData, setStatData] = useState({
  //   totalCompletedRequests: 0,
  //   totalCanceledRequests: 0,
  //   totalPendingRequests: 0,
  //   totalOpenRequests: 0,
  //   totalProviders: 0,
  //   totalCustomers: 0,
  //   totalRequests: 0,
  //   totalAdmins: 0,
  //   totalTransactions: 0,
  //   totalSumOfDeposits: 0,
  //   totalSumOfWithdrawals: 0,
  //   totalCharges: 0,
  //   totalAppFeedback: 0,
  // });

  const getStats = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getStats,
    })
      .then((resp) => {
        const result = resp.data.data;
        setStatData([
          { count: result?.totalCustomers, countName: "Customers" },
          { count: result?.totalProviders, countName: "Service Providers" },
          { count: result?.totalAdmins, countName: "Admins" },
          { count: result?.totalTransactions, countName: "Total Transactions" },
          { count: result?.totalRequests, countName: "Total Requests" },
          {
            count: result?.totalSumOfDeposits,
            countName: "Amount Deposited(NGN)",
          },
          {
            count: result?.totalSumOfWithdrawals,
            countName: "Amount Withdrawn(NGN)",
          },
          { count: result?.totalCharges, countName: "Profit Generated(NGN)" },
          {
            count: result?.totalCanceledRequests,
            countName: "Canceled Requests",
          },
          {
            count: result?.totalOpenRequests,
            countName: "Open Requests",
          },
          {
            count: result?.totalPendingRequests,
            countName: "Pending Requests",
          },
          {
            count: result?.totalCompletedRequests,
            countName: "Completed Requests",
          },
          { count: result?.totalAppFeedback, countName: "App Feedbacks" },
          { count: result?.totalReferrals, countName: "Total Referrals" },
          {
            count: result?.totalProviderPosts,
            countName: "Total Provider Posts",
          },
        ]);
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  const getCustomersGrowth = () => {
    // setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getCustomersStat,
    })
      .then((resp) => {
        const result = resp.data.data;
        setCustomersStat(result);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  const getProvidersGrowth = () => {
    // setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getProvidersStat,
    })
      .then((resp) => {
        const result = resp.data.data;
        setProvidersStat(result);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  const getSearchesGrowth = () => {
    // setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getSearchesStat,
    })
      .then((resp) => {
        const result = resp.data.data;
        setSearchesStat(result);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  const getRequestsGrowth = () => {
    // setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getRequestsStat,
    })
      .then((resp) => {
        const result = resp.data.data;
        setRequestsStat(result);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  const getTransactionsGrowth = () => {
    // setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getTransactionsStat,
    })
      .then((resp) => {
        const result = resp.data.data;
        setTransStat(result);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getCustomersGrowth();

    getProvidersGrowth();

    getTransactionsGrowth();

    getRequestsGrowth();

    getSearchesGrowth();
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" loaderText="Please wait..." />;
      case SUCCESS:
        return (
          <>
            <OVSummary statData={statData} />
            <div className="overview-analysis">
              <OVAnalysis
                title="Customers Growth"
                subTitle="Analysis of customer acquisitions."
                data={customersStat}
              />
            </div>
            <div className="overview-analysis">
              <OVAnalysis
                title="Service Providers Growth"
                subTitle="Analysis of service providers acquisitions."
                data={providersStat}
              />
            </div>
            <div className="overview-analysis">
              <OVAnalysis
                title="Transactions"
                subTitle="Analysis of transactions done"
                data={transStat}
              />
            </div>
            <div className="overview-analysis">
              <OVAnalysis
                title="Searches"
                subTitle="Analysis of searches made by users"
                data={searchesStat}
              />
            </div>
            <div className="overview-analysis">
              <OVAnalysis
                title="Requests"
                subTitle="Analysis of requests opened by customers"
                data={requestsStat}
              />
            </div>
          </>
        );
      case ERROR:
        return <ErrorView handleRetry={getStats} />;
      default:
        return "";
    }
  };

  return (
    <>
      <div className="overview">
        <OVSummaryButton handleOpenModal={() => setOpenModal(true)} />
        {renderBasedOnStatus()}
      </div>

      <CreateAdminUser isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default AdminOverview;
