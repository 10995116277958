import { animated } from "react-spring";

interface Props {
  categoryName: string;
  image: string;
  style: any;
}

const ServiceCard = (props: Props) => {
  const { categoryName, image, style } = props;
  return (
    <div className="app-service" data-aos="zoom-in">
      <animated.div
        className="img"
        style={{
          ...style,
          backgroundImage: `url(${image})`,
        }}
      />
      <h5>{categoryName}</h5>
    </div>
  );
};

export default ServiceCard;
