import assets from "assets/indext";

import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  links: Array<{
    text: string;
    url: string;
    isIcon?: boolean;
  }>;
}

const VisitorFooterBlock = (props: Props) => {
  const { title, links } = props;
  return (
    <div>
      <h3>{title}</h3>
      {links.map(({ text, url, isIcon }, key) => {
        return (
          <React.Fragment key={key}>
            {isIcon ? (
              <a
                href="https://www.linkedin.com/company/serviceandme/"
                target={"__blank"}
              >
                <img
                  src={assets.icons.linkedin}
                  className="visitor-footer__icon"
                  alt="LinkedIn"
                />
              </a>
            ) : (
              <>
                {url ? (
                  <Link className="visitor-footer__link" to={url}>
                    <p key={key}>{text}</p>
                  </Link>
                ) : (
                  <p key={key}>{text}</p>
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default VisitorFooterBlock;
