import "./Testimonials.scss";
import TestimonialsCard from "./TestimonialsCard/TestimonialsCard";

const testimonialsList = [
  {
    name: "Jerome",
    text: "The best app to request a laundry service across Africa",
  },
  {
    name: "Toye",
    text: "I find it reliable and fast as the service provider delivers within minutes",
  },
  {
    name: "Adeola",
    text: "ServiceandMe is the coolest app ever. As a service provider, it reduces the cost of running my Makeup service as I now receive orders on my phone and I  could also keep my customer updated about the latest thing in the makeup world",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials" id="app-features">
      <h3 className="landing-page__title" data-aos="zoom-in">
        TESTIMONIALS
      </h3>
      <p className="landing-page__description" data-aos="zoom-in">
        Read what our users have said about us.
      </p>
      <div className="testimonials__list">
        {testimonialsList.map((content, key) => (
          <TestimonialsCard {...content} key={key} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
