import { Button } from "components/ui";

const CreateCategoryButton = (props: { handleOpen: Function }) => {
  const { handleOpen } = props;
  return (
    <>
      <Button text="Add Category" onClick={handleOpen} />
    </>
  );
};

export default CreateCategoryButton;
