import { SideModal } from "components/ui";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "utils/api";
import API from "utils/api/API";
import { UserType } from "../../users.d";
import ViewUserProfile from "./ViewUserProfile/ViewUserProfile";

interface Props {
  isOpen: boolean;
  onClose: Function;
  canBeDisabled?: boolean;
  canBeDeleted?: boolean;
  user: UserType;
  _id?: string;
  loadFromServer?: boolean;
  serviceRenderer?: string;
  initiator?: {
    email?: string;
    phoneNumber?: string;
  };
}

const ViewUserModal = (props: Props) => {
  const { isOpen, onClose, user, loadFromServer, initiator, serviceRenderer } =
    props;

  const [loading, setLoading] = useState(false);

  const [userDetails, updateUserDetails] = useState<UserType>({});

  const { firstName, lastName, serviceName } = userDetails;

  const getUserDetails = () => {
    setLoading(true);
    API({
      method: apiQueryMethods?.GET,

      url: apiUrls?.getUser(
        initiator?.email,
        "customer",
        initiator?.phoneNumber
      ),
      params: {},
    })
      .then((resp) => {
        const result = resp.data.data;
        result.user.userType = result?.userType;
        updateUserDetails(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        updateUserDetails({});
      });
  };

  useEffect(() => {
    initiator ? getUserDetails() : updateUserDetails(user);
    // eslint-disable-next-line
  }, [loadFromServer, user, initiator, serviceRenderer]);

  return (
    <>
      <SideModal
        isOpen={isOpen}
        onClose={onClose}
        title={serviceName ? serviceName : firstName + " " + lastName}
        subTitle={`${
          userDetails?.user?.userType
            ? userDetails?.user?.userType +
              " (" +
              userDetails?.user?.status +
              ")"
            : "Admin"
        }`}
        titleClass="view-user__title"
      >
        <ViewUserProfile {...props} user={userDetails} loading={loading} />
      </SideModal>
    </>
  );
};

export default ViewUserModal;
