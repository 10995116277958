import { Card } from "components/ui";
import "../AdminAuth.scss";
import SignupForm from "./SignupForm/SignupForm";

const AdminSignup = () => {
  return (
    <>
      <div className="admin-auth">
        <Card className="admin-auth__card">
          <h1> CREATE AN ACCOUNT</h1>
          <SignupForm />
        </Card>
      </div>
    </>
  );
};

export default AdminSignup;
