import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiQueryMethods, apiUrls } from "utils/api";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { Button, Input } from "components/ui";
import "./CreateCategoryForm.scss";
import API from "utils/api/API";

interface Props {
  getCategories?: Function;
  updateApiResponse: Function;
  isEdit?: boolean;
  categoryId?: string;
  categoryName?: string;
  onClose?: Function;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;
const CreateCategoryForm = (props: Props) => {
  const {
    getCategories,
    updateApiResponse,
    isEdit,
    categoryId,
    categoryName,
    onClose,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [status, setStatus] = useState(IDLE);

  const createCategory = (data: any) => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.createCategory,
      data,
    })
      .then(() => {
        setStatus(SUCCESS);
        reset();
        getCategories?.();
        setTimeout(() => {
          onClose?.();
        }, 2000);
      })
      .catch((err) => {
        updateApiResponse({
          status: ERROR,
          message: "An error occurred.",
        });
        setStatus(ERROR);
      });
  };

  const editCategory = (data: any) => {
    setStatus(LOADING);
    updateApiResponse({
      status: IDLE,
      message: "",
    });
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.editCategory(categoryId || ""),
      data,
    })
      .then((resp) => {
        const { message } = resp.data;
        setStatus(SUCCESS);
        updateApiResponse({
          status: SUCCESS,
          message,
        });
        getCategories?.();
      })
      .catch((err) => {
        updateApiResponse({
          status: ERROR,
          message: "An error occurred.",
        });
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? editCategory(data) : createCategory(data);
  };

  useEffect(() => {
    setValue("category", categoryName);
    // eslint-disable-next-line
  }, [categoryName]);

  return (
    <>
      <form className="create-category" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Category Name"
          placeholder="Enter Name Of Category Here."
          hasError={errors?.categoryName ? true : false}
          name={"category"}
          register={register}
          required
        />
        <Button
          text="Save"
          className="create-category__button"
          isLoadingText="Saving..."
          status={status}
        />
      </form>
    </>
  );
};

export default CreateCategoryForm;
