import { DropdownMenu } from "components/ui";
import TRANSACTIONS_ACTIONS from "../../enum";

const TransactionsTableActions = (props: { handleAction: Function }) => {
  const { handleAction } = props;

  const handleView = () => {
    handleAction(TRANSACTIONS_ACTIONS.VIEW_USER, {});
  };

  const actions = [{ text: "View User", handleAction: handleView }];

  return <DropdownMenu menuTitle="" items={actions} />;
};

export default TransactionsTableActions;
