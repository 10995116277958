import { useEffect, useState } from "react";

import { useAppContext } from "AppContext";
import { useForm } from "react-hook-form";

import { Button, Input } from "components/ui";
import { dataQueryStatus } from "utils/dataQueryStatus";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const PersonalInfo = () => {
  const { user } = useAppContext();

  const { firstName, lastName, email } = user;

  const [status, setStatus] = useState(IDLE);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("email", email);
    // eslint-disable-next-line
  }, [user]);

  const onSubmit = (data: any) => {
    console.log("resp", data);
    setStatus(LOADING);
    setStatus(SUCCESS);
    setStatus(ERROR);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="First Name"
          placeholder="Update Your First Name Here."
          inputClass="personal-details__input"
          hasError={errors?.firstName ? true : false}
          name="firstName"
          register={register}
          required
        />
        <Input
          label="Last Name"
          inputClass="personal-details__input"
          placeholder="Udate Your Last Name Here."
          hasError={errors?.lastName ? true : false}
          name="lastName"
          register={register}
          required
        />
        <Input
          label="Email"
          inputClass="personal-details__input"
          placeholder="Update Your Email Here."
          hasError={errors?.email ? true : false}
          name="email"
          register={register}
          required
        />
        <Button
          text="Update"
          className="personal-details__button"
          status={status}
          invertStyle
        />
      </form>
    </>
  );
};

export default PersonalInfo;
