import { Col, Row } from "react-bootstrap";
import OVSummaryCard from "./OVSummaryCard/OVSummaryCard";

const OVSummary = (props: { statData: Array<{}> }) => {
  const { statData } = props;

  return (
    <>
      <Row>
        {statData.map((item, key) => (
          <Col key={key} lg={2} xs={6} md={3}>
            <OVSummaryCard {...item} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OVSummary;
