import { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import AdminLayoutHeader from "./AdminLayoutHeader";
import AdminLayoutSidebar from "./AdminLayoutSidebar";
import "./AdminLayout.scss";

const AdminLayout = (props: { children?: ReactNode }) => {
  const { children } = props;
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const { pathname } = useLocation();
  const path = pathname.substring(1);
  const hideLayout = path.includes("signup") || path.includes("login");

  return (
    <>
      {!hideLayout ? (
        <>
          <div className="admin-layout">
            <AdminLayoutHeader toggleSidebar={toggleSidebar} open={open} />
            <AdminLayoutSidebar toggleSidebar={toggleSidebar} open={open} />
          </div>
          <div className="admin-main">
            <div className={open ? "admin-main-expand" : ""}>
              <div className="admin-main-inner">{children}</div>
            </div>
          </div>
        </>
      ) : (
        <div className="admin-main-inner">{children}</div>
      )}
    </>
  );
};

export default AdminLayout;
