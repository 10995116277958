import { Link } from "react-scroll";
import { Col, Row } from "react-bootstrap";
import "./VisitorHeader.scss";
import VisitorHeaderLogo from "./VisitorHeaderLogo/VisitorHeaderLogo";
import { useNavigate } from "react-router-dom";

const VisitorHeader = () => {
  const navigate = useNavigate();
  const redirectUrl = () => {
    navigate("/");
  };
  return (
    <>
      <header className="visitor-header">
        <div className="visitor-header--inner">
          <Row>
            <Col xs={2} className="visitor-header__col">
              <VisitorHeaderLogo />
            </Col>
            {/* <Col className="visitor-header__col">
              <Link
                activeClass="active"
                to="app-features"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                onClick={() => redirectUrl()}
              >
                <span>Features</span>
              </Link>
            </Col> */}
            <Col className="visitor-header__col">
              <Link
                activeClass="active"
                to="app-services"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                onClick={() => redirectUrl()}
              >
                <span>Our Services</span>
              </Link>
            </Col>
          </Row>
        </div>
      </header>
    </>
  );
};
export default VisitorHeader;
