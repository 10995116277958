import { SideModal } from "components/ui";
import "./CreateAdminUser.scss";
import CreateAdminUserForm from "./CreateAdminUserForm/CreateAdminUserForm";

interface Props {
  isOpen: boolean;
  onClose: Function;
}
const CreateAdminUser = (props: Props) => {
  const { isOpen, onClose } = props;
  return (
    <>
      <SideModal
        title="CREATE ADMIN USER"
        subTitle="Invite An Admin"
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="create-admin-user">
          <CreateAdminUserForm />
        </div>
      </SideModal>
    </>
  );
};

export default CreateAdminUser;
