import { Search } from "components/ui";
import Heading from "components/ui/Heading";
import Select from "components/ui/Select";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "utils/api";
import API from "utils/api/API";

const ServiceProvidersHeading = (props: { handleChange: Function }) => {
  const { handleChange } = props;

  const [categories, setCategories] = useState([]);

  const getCategories = () => {
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getCategories,
    })
      .then((resp) => {
        const result = resp.data.data;

        setCategories(
          result?.map((category: any) => {
            return {
              label: category?.category,
              value: category?._id,
            };
          })
        );
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Heading
        title={"Service Providers "}
        subTitle={"Manage all Service Providers Here"}
      />
      <div className="service-heading--upper-filter">
        <Select
          options={[
            {
              label: "Choose Filter",
              value: "None",
            },
            {
              label: "Service Name",
              value: "serviceName",
            },
            { label: "Email", value: "email" },
            {
              label: "Phone Number",
              value: "phoneNumber",
            },
            // { label: "Description", value: "description" },
            {
              label: "Any Location Match",
              value: "location",
            },
            {
              label: "State Only",
              value: "state",
            },
            {
              label: "City Only",
              value: "city",
            },
            {
              label: "Referrals Order",
              value: "referrals",
            },
          ]}
          onChange={(value: string) => handleChange("filterType", value)}
        />
        <Search
          className="admins-search service-heading--upper-filter--search"
          placeholder="Search for service providers here..."
          onChange={(value: string) => handleChange("keyword", value)}
        />
      </div>
      <div className="service-heading__filter">
        <Select
          options={[
            {
              label: "Choose Category",
              value: "None",
            },
            ...categories,
          ]}
          onChange={(value: string) => handleChange("category", value)}
        />
        <Search
          placeholder="Search locations"
          className="service-heading__filter--search"
        />
        <Select
          placeholder="Select searched locations"
          className="service-heading__filter--location"
        />
      </div>
    </>
  );
};
export default ServiceProvidersHeading;
