import { SideModal } from "components/ui";
import ChangeMyPasswordForm from "./ChangeMyPasswordForm/ChangeMyPasswordForm";

interface Props {
  isOpen: boolean;
  onClose: Function;
}
const ChangeMyPassword = (props: Props) => {
  const { isOpen, onClose } = props;
  return (
    <>
      <SideModal
        isOpen={isOpen}
        onClose={onClose}
        title="Change Password"
        subTitle="Update My Password Here."
      >
        <ChangeMyPasswordForm />
      </SideModal>
    </>
  );
};

export default ChangeMyPassword;
