import LoginForm from "./LoginForm/LoginForm";
import { Card } from "components/ui";
import "../AdminAuth.scss";

const AdminLogin = () => {
  return (
    <>
      <div className="admin-auth">
        <Card className="admin-auth__card">
          <h1> WELCOME BACK!</h1>
          <LoginForm />
        </Card>
      </div>
    </>
  );
};

export default AdminLogin;
