import assets from "assets/indext";
import { Button } from "components/ui";
import "./DownloadPage.scss";

const DownloadPage = () => {
  return (
    <>
      <div className="download-app">
        <span>.</span>
        <h1>Download Our App Here!</h1>
        <div className="download-app__image">
          <img
            className="bounce"
            src={assets.images?.requestView}
            alt="Mobile View"
          />
        </div>
        <div className="download-app__button">
          <a
            href="https://play.google.com/store/apps/details?id=com.serviceandme"
            target="__blank"
          >
            <Button
              text="Download From Playstore"
              icon={assets.icons.playstore}
              invertStyle={true}
            />
          </a>
        </div>
        <div className="download-app__button">
          <a
            href="https://apps.apple.com/app/serviceandme-inc/id6443906232"
            target="__blank"
          >
            <Button
              text="Download From Appstore"
              icon={assets.icons.appStore}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default DownloadPage;
