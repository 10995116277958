import {
  EmptyView,
  ErrorView,
  Loader,
  Paginate,
  Rating,
  Table,
} from "components/ui";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "utils/api";
import API from "utils/api/API";
import { dataQueryStatus } from "utils/dataQueryStatus";
import { getFormattedDate } from "utils/helper";
import "./AppFeedbackTable.scss";

const head = ["Email", "What To Improve", "Comment", "Rating", "Date"];

const { IDLE, NULLMODE, DATAMODE, ERROR, LOADING } = dataQueryStatus;

const AppFeedbackTable = () => {
  const [status, setStatus] = useState(IDLE);
  const [feedbackList, setFeedbackList] = useState([]);

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    console.log(result);
  };

  const getAppFeedback = () => {
    setStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getAppFeedback,
    })
      .then((resp) => {
        const result = resp.data.data;

        const formattedList = result?.map((eachFeedback: any) => {
          const { email, phoneNumber } = eachFeedback?.userId;
          return {
            email: email || phoneNumber,
            feedback: eachFeedback?.feedback,
            whatToImprove: eachFeedback?.whatToImprove,
            rating: <Rating rateValue={eachFeedback?.rating} useRed={true} />,
            data: <div>{getFormattedDate(eachFeedback?.created_at)}</div>,
          };
        });

        setFeedbackList(formattedList);
        result.length === 0 ? setStatus(NULLMODE) : setStatus(DATAMODE);
      })
      .catch((err) => {
        setFeedbackList([]);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
        return "";
      case LOADING:
        return <Loader className="loader-no-bg" />;
      case DATAMODE:
        return (
          <>
            <Table
              head={head}
              body={feedbackList}
              tableClass="app-feedback--table"
            />
            <Paginate totalItems={100} pageSize={3} onResult={onResult} />
          </>
        );
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getAppFeedback} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    getAppFeedback();
  }, []);

  return (
    <>
      <div>{renderBasedOnStatus()}</div>
    </>
  );
};

export default AppFeedbackTable;
