import AppFeedback from "components/App/AdminApp/AppFeedback/AppFeedback";
import AdminLogin from "components/App/AdminApp/Auth/Login/AdminLogin";
import AdminSignup from "components/App/AdminApp/Auth/Signup/AdminSignup";
import Categories from "components/App/AdminApp/Categories/Categories";
import AdminOverview from "components/App/AdminApp/Overview/AdminOverview";
import Permissions from "components/App/AdminApp/Permissions/Permissions";
import AllRequests from "components/App/AdminApp/Requests/AllRequests";
import AdminSettings from "components/App/AdminApp/Settings/AdminSettings";
import Transactions from "components/App/AdminApp/Transactions/Transactions";
import Admins from "components/App/AdminApp/Users/Admins/Admins";
import Customers from "components/App/AdminApp/Users/Customers/Customers";
import ServiceProviders from "components/App/AdminApp/Users/ServiceProviders/ServiceProviders";
import Withdrawals from "components/App/AdminApp/Withdrawals/Withdrawals";
import { MyRequests, Overview, Settings } from "components/App/ProvidersApp";
import { DownloadPage, LandingPage } from "components/App/VisitorsApp";
import PrivacyPage from "components/App/VisitorsApp/PrivacyPage/PrivacyPage";
import TermsAndConditions from "components/App/VisitorsApp/TermsAndConditions/TermsAndConditions";
import { Login, SignUp } from "pages/Auth";
// import Signup from "pages/Auth/SignUp/SignUp";
import ErrorPage from "pages/ErrorPage/ErrorPage";

export const privateRoutes = [
  {
    title: "Overview",
    icon: "dashboard",
    subRoutes: [],
    component: <Overview />,
    route: "/provider/dashboard/",
  },
  {
    title: "Requests",
    icon: "dashboard",
    subRoutes: [],
    component: <MyRequests />,
    route: "/provider/requests/",
  },
  {
    title: "Settings",
    icon: "dashboard",
    subRoutes: [],
    component: <Settings />,
    route: "/provider/settings",
  },
];

export const publicRoutes = [
  {
    title: "Home Page",
    icon: "",
    subRoutes: [],
    component: <LandingPage />,
    route: "/",
  },
  {
    title: "Download App",
    icon: "",
    subRoutes: [],
    component: <DownloadPage />,
    route: "/download-app/",
  },
  {
    title: "Download App",
    icon: "",
    subRoutes: [],
    component: <PrivacyPage />,
    route: "/privacy-policy/",
  },
  {
    title: "Download App",
    icon: "",
    subRoutes: [],
    component: <TermsAndConditions />,
    route: "/terms-and-conditions/",
  },
  {
    title: "Auth",
    icon: "",
    route: "/",
    subRoutes: [
      {
        subTitle: "Login",
        subIcon: "",
        route: "/login/",
        component: <Login />,
      },
      {
        subTitle: "Sign Up",
        subIcon: "",
        route: "/signup/",
        component: <SignUp />,
      },
    ],
  },
];

export const exceptionRoutes = [
  {
    title: "Error Page",
    icon: "",
    subRoutes: [],
    component: <ErrorPage />,
    route: "*",
  },
];

export const adminRoutes = [
  {
    title: "Overview",
    icon: "dashboard",
    subRoutes: [],
    component: <AdminOverview />,
    route: "/admin",
  },
  {
    title: "Users",
    icon: "user",
    route: "/admin/users",
    subRoutes: [
      {
        subTitle: "Admins",
        subIcon: "",
        route: "/admins/",
        component: <Admins />,
      },
      {
        subTitle: "Customers",
        subIcon: "",
        route: "/admin/customers/",
        component: <Customers />,
      },
      {
        subTitle: "Service Providers",
        subIcon: "",
        route: "/admin/service-providers",
        component: <ServiceProviders />,
      },
    ],
  },
  {
    title: "Requests",
    icon: "money",
    subRoutes: [],
    route: "/admin/requests/",
    component: <AllRequests />,
  },
  {
    title: "Transactions",
    icon: "money",
    subRoutes: [],
    route: "/admin/transactions/",
    component: <Transactions />,
  },
  {
    title: "Withdrawals",
    icon: "money",
    subRoutes: [],
    route: "/admin/withdrawals/",
    component: <Withdrawals />,
  },
  {
    title: "Categories",
    icon: "tasks",
    subRoutes: [],
    route: "/admin/categories/",
    component: <Categories />,
  },
  {
    title: "App Feedback",
    icon: "feedback",
    subRoutes: [],
    route: "/admin/app-feedback/",
    component: <AppFeedback />,
  },
  {
    title: "Permissions",
    icon: "lock",
    subRoutes: [],
    route: "/admin/app-permissions/",
    component: <Permissions />,
  },
  {
    title: "Settings",
    icon: "settings",
    subRoutes: [],
    component: <AdminSettings />,
    route: "/admin/settings/",
  },
  {
    title: "Login",
    icon: "none",
    subRoutes: [],
    component: <AdminLogin />,
    route: "/admin/login/",
  },
  {
    title: "Signup",
    icon: "none",
    subRoutes: [],
    component: <AdminSignup />,
    route: "/admin/signup/",
  },
];
