import { DropdownMenu } from "components/ui";

const RequestsTableActions = (props: {
  handleAction: Function;
  _id?: string;
  initiator?: string;
  serviceRenderer?: string;
}) => {
  const { handleAction, serviceRenderer, initiator } = props;

  const handleView = () => {
    handleAction({ initiator, serviceRenderer });
  };

  const actions = [
    { text: "View Initiator", handleAction: handleView },
    {
      text: "View Service Renderer",
      handleAction: handleView,
    },
  ];

  return <DropdownMenu menuTitle="" items={actions} />;
};

export default RequestsTableActions;
