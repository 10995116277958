import { UserType } from "components/App/AdminApp/Users/users.d";
import UserCard from "../../../common/UserCard/UserCard";

interface Props {
  handleAction: Function;
  user: UserType;
}

const ServiceProvidersUserCard = (props: Props) => {
  const { handleAction, user } = props;
  return <UserCard handleAction={handleAction} user={user} />;
};

export default ServiceProvidersUserCard;
