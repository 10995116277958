import { DefaultModal } from "components/ui";
import TRANSACTIONS_ACTIONS from "../../enum";
interface Props {
  openModal: boolean;
  setOpenModal: Function;
  actionType: string;
  selectedTrans: Object;
}

const TransactionsModal = (props: Props) => {
  const { openModal, setOpenModal, actionType } = props;
  const renderBasedOnActionType = () => {
    switch (actionType) {
      case TRANSACTIONS_ACTIONS.VIEW_USER:
        return "";
      case TRANSACTIONS_ACTIONS.DELETE_TRANSACTION:
        return (
          <DefaultModal isOpen={openModal} onClose={() => setOpenModal(false)}>
            <h3>Are You Sure?</h3>
          </DefaultModal>
        );
      default:
        return "";
    }
  };

  return <>{renderBasedOnActionType()}</>;
};

export default TransactionsModal;
