import { Col, Row } from "react-bootstrap";
import "./VisitorFooter.scss";
import VisitorFooterBlock from "./VisitorFooterBlock/VisitorFooterBlock";

const footerItems = [
  {
    title: "About Us",
    links: [
      { text: "Home", url: "/" },
      { text: "Privacy Policy", url: "/privacy-policy" },
      { text: "Terms & Conditions", url: "/terms-and-conditions" },
    ],
  },
  {
    title: "Product",
    links: [
      // { text: "Sign Up", url: "" },
      // { text: "Login", url: "" },
      { text: "Download App", url: "/download-app/" },
    ],
  },
  {
    title: "Follow Us",
    links: [
      // { text: "twitter", url: "", isIcon: true },
      { text: "linkedIn", url: "", isIcon: true },
    ],
  },
];

const VisitorFooter = () => {
  return (
    <>
      <footer className="visitor-footer">
        <div className="visitor-footer--inner">
          <Row>
            {footerItems.map((item, key) => (
              <Col xs={6} sm={4} key={key} className="visitor-footer__block">
                <VisitorFooterBlock {...item} />
              </Col>
            ))}
          </Row>
        </div>

        <p className="visitor-footer-cp">
          © {new Date().getFullYear()} <span>ServiceandMe.</span> All Right
          Reserved.
        </p>
      </footer>
    </>
  );
};
export default VisitorFooter;
