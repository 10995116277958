// import assets from "assets/indext";
import { Link } from "react-router-dom";

const VisitorHeaderLogo = () => {
  return (
    <>
      <Link to="/">
        <img
          src={
            "https://res.cloudinary.com/dtdla1h52/image/upload/v1666107811/WebApp/BrandIdentity/ServiceandMe_Icon_bfncmy.png"
          }
          alt="ServiceandMe"
        />
      </Link>
    </>
  );
};

export default VisitorHeaderLogo;
