import { useAppContext } from "AppContext";

import { Alert, Button, Input } from "components/ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "utils/api";
import API from "utils/api/API";
import { dataQueryStatus } from "utils/dataQueryStatus";
import "../../AdminAuth.scss";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;
const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { updateToken, updateUser } = useAppContext();

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const logIn = (data: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.logIn,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
        const { firstName, email, isActive, lastName, token } = resp.data.data;
        updateUser({ firstName, email, isActive, lastName });
        updateToken(token);
        setTimeout(() => {
          navigate("/admin/");
        }, 2000);
      })
      .catch((err) => {
        setMessage("Please Enter the Correct Credentials!");
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => logIn(data);

  return (
    <>
      <Alert
        title=""
        text="Redirecting you to dashboard."
        direction="RIGHT"
        show={status === SUCCESS ? true : false}
      />

      <form className="admin-auth-form" onSubmit={handleSubmit(onSubmit)}>
        <div className={`message ${status === SUCCESS ? "success" : "error"}`}>
          {message}
        </div>
        <Input
          placeholder="Enter Email Address"
          inputClass="admin-auth-form__input"
          register={register}
          name="email"
          type="email"
          hasError={errors?.email ? true : false}
        />
        <Input
          placeholder="Enter Password"
          inputClass="admin-auth-form__input"
          name="password"
          type="password"
          hasError={errors?.password ? true : false}
          register={register}
        />
        <Button
          text="Login"
          className="admin-auth-form__button"
          isLoadingText="Please wait..."
          status={status}
        />
      </form>

      <div className="admin-auth-link">
        <Link to="/" className="admin-auth-link__forgot">
          Forgot Password
        </Link>

        <div className="admin-auth-link__login">
          Don't have an account?
          <Link to="/admin/signup/" className="admin-auth-link__started">
            {" "}
            Get Started
          </Link>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
